import React from "react";
import HeaderRow from "../global/HeaderRow";
import MuscleRow from "./MuscleRow";
import useUserRoles from "../../hooks/auth/useUserRoles";

const MuscleTable = ({ muscles, handleEdit, handleDelete }) => {
  const { isSuperAdmin } = useUserRoles();

  const renderMuscles = () => {
    if (Array.isArray(muscles)) {
      if (muscles.length === 0) {
        return <p className="m-2 small">No hay músculos registrados</p>;
      }
      return muscles.map((muscle) => (
        <MuscleRow
          muscle={muscle}
          key={muscle.muscle_id}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      ));
    }
  };

  const getHeaders = () => {
    const headers = ["Nombre", "Categoría"];
    if(isSuperAdmin()) headers.push("Acciones");

    return headers;
  }

  return (
    <div className="container-fluid">
      <HeaderRow headers={getHeaders()} />
      {renderMuscles()}
    </div>
  );
};

export default MuscleTable;
