import { navigate } from "@reach/router";
import React, { createContext, useReducer, useContext } from "react";
import CustomerReducer from "../reducers/CustomerReducer";
import CustomerService from "../services/CustomerService";
import {
  SET_CUSTOMER,
  CREATE_CUSTOMER,
  CUSTOMERS_RECIBIDOS,
  SET_PROPIEDAD_CUSTOMER,
  SHOW_SPINNER,
  HIDE_SPINNER,
  LINK_RECIBIDO,
} from "../types";
import { hideModal } from "../utils";
import { ModalContext } from "./ModalContext";
import { BranchesContext } from "./BranchesContext";

const initialState = {
  customers: null,
  customer: null,
  link: null,
};

export const CustomerContext = createContext(initialState);

export const CustomerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CustomerReducer, initialState);

  const { success, alert } = useContext(ModalContext);
  const { branch } = useContext(BranchesContext);

  const getCustomersByQuery = (query) => {
    CustomerService.getCustomersByQuery(query).then((res) => {
      const { customers } = res.data;
      dispatch({ type: CUSTOMERS_RECIBIDOS, payload: customers });
    });
  };

  const getAllCustomers = (filters) => {
    dispatch({ type: CUSTOMERS_RECIBIDOS, payload: null });
    CustomerService.getAllCustomers(filters).then((res) => {
      const { customers } = res.data;
      dispatch({ type: CUSTOMERS_RECIBIDOS, payload: customers });
    });
  };

  const getOnlineActiveCustomers = (filters) => {
    CustomerService.getOnlineActiveCustomers(filters).then((res) => {
      const { customers } = res.data;
      dispatch({ type: CUSTOMERS_RECIBIDOS, payload: customers });
    });
  };

  const getStudioActiveCustomers = () => {
    CustomerService.getStudioActiveCustomers().then((res) => {
      const { customers } = res.data;
      dispatch({ type: CUSTOMERS_RECIBIDOS, payload: customers });
    });
  };

  const getCustomer = (customer_id, filters) => {
    CustomerService.getCustomer(customer_id, filters).then((res) => {
      const { customer } = res.data;
      dispatch({ type: SET_CUSTOMER, payload: customer });
    });
  };

  const extenderAcceso = (data) => {
    CustomerService.extenderAcceso(data).then(() => {
      success("¡Acceso agregado!");
      getCustomer(data.customer_id);
      hideModal();
    });
  };

  const revokeAccess = (purchase_id, customer_id) => {
    CustomerService.revokeAccess(purchase_id).then((res) => {
      getCustomer(customer_id);
      success("¡Acceso eliminado!");
      hideModal();
    });
  };

  const removeClasses = (customer_id, amount) => {
    CustomerService.removeClasses(customer_id, amount).then(() => {
      getCustomer(customer_id);
      success("¡Clases Restadas!");
      hideModal();
    });
  };

  const clearCustomer = () => {
    dispatch({ type: SET_CUSTOMER, payload: null });
  };

  const setPropiedadCustomer = (key, value) => {
    dispatch({ type: SET_PROPIEDAD_CUSTOMER, payload: { key, value } });
  };

  const createCustomer = () => {
    dispatch({ type: CREATE_CUSTOMER, payload: branch?.branch_id });
  };

  const postCustomer = (customer) => {
    dispatch({ type: SHOW_SPINNER });

    const handleSuccess = ({ data }) => {
      success("Cliente guardado con éxito.");
      dispatch({ type: HIDE_SPINNER });
      if (data.customer) {
        navigate(`/myadmin/customer/${data.customer.customer_id}`);
      } else if (!isNaN(customer.customer_id)) {
        navigate(`/myadmin/customer/${customer.customer_id}`);
      }
    };

    const handleError = (error) => {
      dispatch({ type: HIDE_SPINNER });
      if (error.response) {
        if (error.response.status === 409) {
          return alert("Ya existe un usuario con este correo.");
        }
      }
      alert(error);
    };
    let data = { ...customer };
    delete data.available_classes;
    delete data.class_reservations;
    delete data.purchases;
    delete data.invoices;
    if (isNaN(data.customer_id)) {
      CustomerService.postCustomer(data).then(handleSuccess).catch(handleError);
    } else {
      CustomerService.putCustomer(data).then(handleSuccess).catch(handleError);
    }
  };

  const deleteCustomer = (customer_id) => {
    CustomerService.deleteCustomer(customer_id).then((res) => {
      navigate("/myadmin/customers");
      success("Cliente eliminado con éxito.");
      hideModal();
    });
  };

  const removeCustomerClasses = (customer_id, amount) => {
    CustomerService.removeClasses(customer_id, amount).then(() => {
      success("Clases eliminadas con éxito.");
      hideModal();
      getCustomer(customer_id);
    });
  };

  const addCustomerClasses = (data, callback) => {
    CustomerService.giveClasses(data).then(
      () => {
        success("Clases agregadas con éxito.");
        hideModal();
        if(typeof callback === 'function') callback();
      }
    );
  };

  const saveCustomerOnlineAccess = (data) => {
    let service = CustomerService.putOnlineAccess;
    if (isNaN(data.online_access_id)) {
      service = CustomerService.postOnlineAccess;
    }

    service(data)
      .then((res) => {
        success("Acceso Online Modificado");
        hideModal();
        getCustomer(data.customer_id);
      }).catch(err => {
        alert(err);
      });
  }

  const getPasswordResetLink = (email) => {
    CustomerService.getPasswordResetLink(email).then((res) => {
      const { link } = res.data;
      dispatch({ type: LINK_RECIBIDO, payload: link });
    });
  };

  const clearLink = () => {
    dispatch({ type: LINK_RECIBIDO, payload: null });
  };

  return (
    <CustomerContext.Provider
      value={{
        ...state,
        clearLink,
        getCustomer,
        getAllCustomers,
        getCustomersByQuery,
        extenderAcceso,
        clearCustomer,
        revokeAccess,
        removeClasses,
        createCustomer,
        postCustomer,
        saveCustomerOnlineAccess,
        deleteCustomer,
        addCustomerClasses,
        setPropiedadCustomer,
        removeCustomerClasses,
        getPasswordResetLink,
        getOnlineActiveCustomers,
        getStudioActiveCustomers,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};
