import React from "react";
import HeaderRow from "../global/HeaderRow";
import CoachCard from "./CoachCard";

const CoachesTable = ({ query, coaches, handleEdit, handleDelete }) => {
  const renderCoaches = () => {
    if (Array.isArray(coaches)) {
      let coachesRender = coaches;
      if (query !== "") {
        coachesRender = coachesRender.filter(
          (coach) =>
            coach.name.toLowerCase().includes(query) ||
            coach.last_name.toLowerCase().includes(query)
        );
      }
      if (coachesRender.length === 0) return <p>No hay coaches registrados.</p>;
      return coachesRender.map((coach) => (
        <CoachCard
          key={coach.instructor_id}
          coach={coach}
          editCoach={handleEdit}
          deleteCoach={handleDelete}
        />
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div>
      <div className="hide-mobile">
        <div className="row border bg-light py-1 mx-0  bold mt-3">
          <div className="col-2" style={{fontSize: '14px'}}> 
            Nombre
          </div>
          <div className="col-2" style={{fontSize: '14px'}}> 
            Instagram
          </div>
          <div className="col-2" style={{fontSize: '14px'}}>
            Cumpleaños
          </div>
          <div className="col-2 px-0 text-center" style={{fontSize: '14px'}}>
            <span>
              <i className="fa fa-star me-1" /> Online
            </span>
          </div>
          <div className="col-2 px-0 text-center" style={{fontSize: '14px'}}>
            <span>
              <i className="fa fa-star me-1" /> Presencial
            </span>
          </div>
          <div className="col-2 text-center" style={{fontSize: '14px'}} >
            Acciones
          </div>
        </div>
      </div>
      {renderCoaches()}
    </div>
  );
};
export default CoachesTable;
