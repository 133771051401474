import React, { useEffect, useContext } from "react";
import LocationForm from "../../components/locations/LocationForm";
import { LocationsContext } from "../../context/LocationsContext";
import { ModalContext } from "../../context/ModalContext";

const AdminLocations = () => {
  const {
    locations,
    setLocation,
    postLocation,
    getLocations,
    createLocation,
    deleteLocation,
    setPropiedadLocation,
  } = useContext(LocationsContext);

  const { modalComponent, clearModal } = useContext(ModalContext);

  useEffect(() => {
    getLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editLocation = (location) => {
    setLocation(location);
    modalComponent(
      "Editar Ubicación",
      <LocationForm
        modifier={setPropiedadLocation}
        postLocation={postLocation}
      />
    );
  };

  const addLocation = () => {
    createLocation();
    modalComponent(
      "Agregar Ubicación",
      <LocationForm
        modifier={setPropiedadLocation}
        postLocation={postLocation}
      />
    );
  };

  const confirmDelete = (location) => {
    modalComponent(
      "Cuidado",
      <div className="container-fluid px-0">
        <p>
          ¿Estás segura que deseas eliminar la ubicación{" "}
          {location?.name?.trim()}? Esta acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() => deleteLocation(location.location_id, clearModal)}
        >
          Eliminar
        </button>
      </div>
    );
  };

  const renderLocations = () => {
    if (locations && locations !== null) {
      return locations?.map((location) => (
        <tr
          key={location.location_id}
          className="small bg-white border-bottom align-middle hover-light"
        >
          <td className="">{location?.name}</td>
          <td className="">{location.description}</td>
          <td className="">{location.address}</td>

          <td className="text-center">
            <button
              className="btn btn-outline-secondary"
              onClick={() => editLocation(location)}
            >
              <i className="fa fa-edit"></i> Editar
            </button>
            <button
              className="btn btn-outline-danger mx-3"
              onClick={() => confirmDelete(location)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      ));
    }
    return <div className="spinner-border"></div>;
  };

  const renderContent = () => {
    if (locations?.length > 0) {
      return (
        <div className="table-responsive">
          <table className="table border">
            <thead className="bg-light border bold small">
              <tr className="bold mx-0 border align-items-center">
                <td>Nombre</td>
                <td>Descripción</td>
                <td className="text-center">Dirección</td>
                <td className="text-center">Acciones</td>
              </tr>
            </thead>
            <tbody>{renderLocations()}</tbody>
          </table>
        </div>
      );
    } else {
      return <p>No hay ubicaciones registradas.</p>;
    }
  };

  return (
    <div className="container-fluid">
      <div className="row border-bottom pb-3 mb-3">
        <div className="col col-md-6">
          <h1 className="mb-0 ">Ubicaciones</h1>
        </div>
        <div className="col col-md-6 text-end">
          <button className="btn btn-primary" onClick={addLocation}>
            + Agregar
          </button>
        </div>
      </div>

      <div className="card p-3 shadow" style={{ cursor: "unset" }}>
        {renderContent()}
      </div>
    </div>
  );
};

export default AdminLocations;
