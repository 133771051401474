import React, { useEffect, useState, useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { durations, getValue, S3_ENDPOINT } from "../../utils";
import { Link } from "@reach/router";
import moment from "moment";
import VideoPropSelect from "../video_props/VideoPropSelect";
import VideoMuscleSelect from "../video_muscles/VideoMuscleSelect";

const VideoForm = ({
  tags,
  video,
  programs,
  modifier,
  postVideo,
  class_types,
  video_types,
  instructors,
  spinner,
}) => {
  const [type, setType] = useState("");
  const [coach, setCoach] = useState("");
  const [picture, setPicture] = useState(null);
  const [addType, setAddType] = useState(false);
  const [addCoach, setAddCoach] = useState(false);
  const [changeImage, setChangeImage] = useState(false);

  const { alert } = useContext(ModalContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = { ...video };
    if (addCoach) {
      data.instructor_id = "nueva";
      data.instructor = {
        first_name: coach,
      };
    }
    if (addType) {
      data.video_type_id = "nuevo";
      data.video_type = {
        name: type,
      };
    }
    if (video.video_type_id === "") {
      return alert("Debes agregar un tipo de video.");
    }
    postVideo(data);
  };

  useEffect(() => {
    if (video !== null) {
      if (video.picture && video.picture !== null && picture === null) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setPicture(e.target.result);
        };
        reader.readAsDataURL(video.picture);
      }
    }
  }, [video]);

  useEffect(() => {
    if (video !== null) {
      if (Array.isArray(class_types)) {
        if (
          video.class_type_id === "" ||
          video.class_type_id === null ||
          !video.class_type_id
        ) {
          if (class_types.length > 0) {
            modifier("class_type_id", class_types[0].class_type_id);
          }
        }
      }
      if (Array.isArray(video_types)) {
        if (
          video.video_type_id === "" ||
          video.video_type_id === null ||
          !video.video_type_id
        ) {
          if (video_types.length > 0) {
            modifier("video_type_id", video_types[0].video_type_id);
          }
        }
      }
      if (Array.isArray(instructors)) {
        if (
          video.instructor_id === "" ||
          video.instructor_id === null ||
          !video.instructor_id
        ) {
          if (instructors.length > 0) {
            modifier("instructor_id", instructors[0].instructor_id);
          }
        }
      }
      if (Array.isArray(tags)) {
        if (video.tag_id === "" || video.tag_id === null || !video.tag_id) {
          if (tags.length > 0) {
            modifier("tag_id", tags[0].tag_id);
          }
        }
      }
      if (Array.isArray(programs)) {
        if (
          video.program_id === "" ||
          video.program_id === null ||
          !video.program_id
        ) {
          if (programs.length > 0) {
            modifier("program_id", programs[0].program_id);
          }
        }
      }
      if (Array.isArray(durations)) {
        if (
          video.duration === "" ||
          video.duration === null ||
          !video.duration
        ) {
          if (durations.length > 0) {
            modifier("duration", durations[0]);
          }
        }
      }
    }
  }, [class_types, video_types, instructors, programs, durations, tags, video]);

  const renderinstructors = () => {
    if (instructors && instructors !== null) {
      return instructors.map((instructor) => (
        <option key={instructor.instructor_id} value={instructor.instructor_id}>
          {instructor.name} {instructor.last_name}
        </option>
      ));
    }
  };

  const renderImage = () => {
    const { thumbnail, file } = video;
    if (thumbnail && thumbnail !== null) {
      if (typeof file === "object") {
        return (
          <img
            src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
            className="video-form-thumbnail d-block"
          />
        );
      }
      return <img src={thumbnail} className="video-form-thumbnail d-block" />;
    }
    if (picture !== null) {
      return <img src={picture} className="video-form-thumbnail d-block" />;
    }
  };

  const renderTypeForm = () => {
    return (
      <select
        className="form-control mb-3"
        onChange={(e) => modifier("video_type_id", e.target.value)}
        value={video.video_type_id}
      >
        {renderVideoTypes()}
      </select>
    );
  };

  const renderPrograms = () => {
    if (Array.isArray(programs)) {
      return programs.map((program) => (
        <option key={program.program_id} value={program.program_id}>
          {program.name}
        </option>
      ));
    }
  };

  const renderProgramForm = () => {
    return (
      <select
        className="form-control mb-3"
        onChange={(e) => modifier("program_id", e.target.value)}
        value={video.program_id}
      >
        {renderPrograms()}
      </select>
    );
  };

  const renderTags = () => {
    if (Array.isArray(tags)) {
      return tags.map((tag) => (
        <option key={tag.tag_id} value={tag.tag_id}>
          {tag.name}
        </option>
      ));
    }
  };

  const renderTagForm = () => {
    return (
      <select
        className="form-control mb-3"
        onChange={(e) => modifier("tag_id", e.target.value)}
        value={video.tag_id}
      >
        {renderTags()}
      </select>
    );
  };

  const renderCoachForm = () => {
    if (addCoach) {
      return (
        <input
          type="text"
          className="form-control mb-3"
          value={coach}
          onChange={(e) => setCoach(e.target.value)}
        />
      );
    }
    return (
      <select
        className="form-control mb-3"
        onChange={(e) => modifier("instructor_id", e.target.value)}
        value={video.instructor_id}
      >
        {renderinstructors()}
      </select>
    );
  };

  const renderImageForm = () => {
    if (changeImage || isNaN(video.video_id)) {
      return (
        <input
          type="file"
          className="form-control mb-3"
          onChange={handleFiles}
        />
      );
    }
    return (
      <button
        className="btn btn-outline-secondary d-block"
        onClick={() => setChangeImage(true)}
      >
        Cambiar Imagen
      </button>
    );
  };

  const renderForm = () => {
    if (video && video !== null) {
      const { name, description, url, class_type_id, available_at } = video;
      return (
        <form onSubmit={handleSubmit}>
          <label>Nombre</label>
          <input
            type="text"
            className="form-control mb-3"
            value={name}
            onChange={(e) => modifier("name", e.target.value)}
          />
          <label>Descripcion</label>
          <input
            type="text"
            className="form-control mb-3"
            value={description}
            onChange={(e) => modifier("description", e.target.value)}
          />
          <div className="row">
            <div className="col-12 col-md-6">
              <label>URL de Video</label>
              <input
                type="text"
                className="form-control mb-3"
                value={url}
                onChange={(e) => modifier("url", e.target.value)}
              />
            </div>
            <div className="col-12 col-md-6">
              <div className="container-fluid px-0">
                <label>Disponible el</label>
                <input
                  type="date"
                  className="form-control mb-3"
                  value={getValue(video, "available_at", "date")}
                  onChange={(e) => modifier("available_at", e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-4 col-md-4"> {renderImage()}</div>
            <div className="col-8 col-md-8">
              <label>Thumbnail</label>
              {renderImageForm()}
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-md-4">
              <label>Tipo de Clase</label>
              <select
                className="form-control mb-3"
                onChange={(e) => {
                  modifier("class_type_id", e.target.value);
                }}
                value={class_type_id}
              >
                {renderClassTypes()}
              </select>
            </div>
            <div className="col-6 col-md-4">
              <label>Impacto</label>
              {renderTypeForm()}
            </div>
            <div className="col-6 col-md-4">
              <label>Programa</label>
              {renderProgramForm()}
            </div>
            <div className="col-6 col-md-4">
              <label>Target</label>
              {renderTagForm()}
            </div>
            <div className="col-6 col-md-4">
              <label>Coach</label>
              {renderCoachForm()}
            </div>
            <div className="col-6 col-md-4">
              <label>Duracion</label>
              <select
                value={getValue(video, "duration")}
                className="form-control mb-3"
                onChange={(e) => modifier("duration", e.target.value)}
              >
                {durations.map((duration) => (
                  <option key={duration} value={duration}>
                    {duration}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-xl-6 px-4">
              <VideoPropSelect />
            </div>
            <div className="col-12 col-xl-6 px-4">
              <VideoMuscleSelect />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <button
                type="submit"
                disabled={spinner}
                className="btn btn-primary"
              >
                {spinner ? <div className="spinner-border"></div> : "Guardar"}
              </button>
            </div>
            <div className="col-6 text-right">
              <Link
                to="/myadmin/videos"
                className="btn btn-link text-secondary"
              >
                Cancelar
              </Link>
            </div>
          </div>
        </form>
      );
    }
    return <div className="spinner-border"></div>;
  };

  const handleFiles = (e) => {
    const currentFile = e.target.files[0];
    modifier("picture", currentFile);
  };

  const renderClassTypes = () => {
    if (class_types && class_types !== null) {
      return class_types.map((class_type) => (
        <option key={class_type.class_type_id} value={class_type.class_type_id}>
          {class_type.name}
        </option>
      ));
    }
  };

  const renderVideoTypes = () => {
    if (Array.isArray(video_types)) {
      return video_types.map((video_type) => (
        <option key={video_type.video_type_id} value={video_type.video_type_id}>
          {video_type.name}
        </option>
      ));
    }
  };

  return <div>{renderForm()}</div>;
};

export default VideoForm;
