import React, { useContext } from "react";
import { ClassInstructorContext } from "../../context/SingleClassContext";
import SingleClassForm from "../../components/schedule/SingleClassForm";
import ScheduleAdmin from "../../components/schedule/ScheduleAdmin";
import PanelTitle from "../../components/global/PanelTitle";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";
import moment from "moment";

const AdminClases = () => {
  const {
    view,
    start_date,
    end_date,
    getSchedule,
    clearClase,
    setPropiedadClase,
  } = useContext(ClassInstructorContext);

  const { clearModal, modalComponent } = useContext(ModalContext);

  const { user } = useContext(AuthContext);

  const handleCancel = () => {
    clearModal();
    clearClase();
  };

  const handleCallback = () => {
    getSchedule({ start_date, end_date });
  };

  const addClase = () => {
    modalComponent(
      "Agregar Clase",
      <SingleClassForm
        handleCallback={handleCallback}
        handleCancel={handleCancel}
        modifier={setPropiedadClase}
      />
    );
  };

  return (
    <div className="container-fluid">
      <PanelTitle
        title="Clases"
        onClick={addClase}
        hideButton={user.role === "coach"}
      />
      <ScheduleAdmin />
    </div>
  );
};

export default AdminClases;
