import React, { useState, useContext, useEffect } from "react";
import { AnaliticasContext } from "../../context/AnaliticasContext";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import OnlineLeaderboardTable from "../../components/analytics/OnlineLeaderboardTable";
import Pagination from "../../components/global/Pagination";

const AnaliticasOnlineLeaderboard = () => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");

  const { leaderboard, getOnlineLeaderboard } = useContext(AnaliticasContext);

  useEffect(() => {
    fetchLeaderboard(startDate, endDate);
  }, [page]);

  const fetchLeaderboard = (start_date, end_date) => {
    setEndDate(end_date);
    setStartDate(start_date);
    getOnlineLeaderboard({ start_date, end_date, page });
  };

  const renderLeaderboard = () => {
    if (Array.isArray(leaderboard)) {
      if (leaderboard.length === 0) {
        return <p>No hay reproducciones registradas.</p>;
      }
      return (
        <OnlineLeaderboardTable
          customers={leaderboard}
          start_date={startDate}
          end_date={endDate}
          query={query}
        />
      );
    }
  };
  const renderCount = () => {
    if (Array.isArray(leaderboard)) {
      return leaderboard.length;
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitleDate title="Online" callback={fetchLeaderboard} />
      <div className="card shadow">
        <div className="row align-items-center">
          <div className="col-12 col-md-8 mb-3">
            <input
              type="text"
              value={query}
              className="form-control"
              placeholder="Buscar por nombre o correo..."
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <div className="col-12 col-md-4 mb-3 text-start text-md-end">
            <h4 className="mb-0">Alumnas: {renderCount()}</h4>
          </div>
        </div>
        {renderLeaderboard()}
        <Pagination setPage={setPage} page={page} />
      </div>
    </div>
  );
};

export default AnaliticasOnlineLeaderboard;
