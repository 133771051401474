import { RESERVATIONS_RECIBIDAS } from "../types";
import {
  RESERVATIONS_RATINGS_RECEIVED,
  RESERVATIONS_RECEIVED,
  SET_CURRENT_FILTER,
} from "../types/reservations";

const ReservationsReducer = (state, { type, payload }) => {
  switch (type) {
    case RESERVATIONS_RECIBIDAS:
      return { ...state, reservations: payload };
    case RESERVATIONS_RECEIVED:
      return { ...state, reservations: payload };
    case SET_CURRENT_FILTER:
      return { ...state, currentReservationsFilter: payload };
    case RESERVATIONS_RATINGS_RECEIVED:
      return { ...state, ratings: payload };
    default:
      return { ...state };
  }
};

export default ReservationsReducer;
