import React from "react";

const CustomContent = ({ tag, content, className }) => {
  const renderContent = () => {
    if (content && content !== null) {
      switch (tag) {
        case "img":
          return (
            <img
              src={content}
              className={`mw-100 w-100 m-auto d-block ${className}`}
            />
          );
        case "h1":
          return <h1 className={className}>{content}</h1>;
        case "h2":
          return <h2 className={className}>{content}</h2>;
        case "h3":
          return <h3 className={className}>{content}</h3>;
        case "h4":
          return <h4 className={className}>{content}</h4>;
        case "h5":
          return <h5 className={className}>{content}</h5>;
        case "h6":
          return <h6 className={className}>{content}</h6>;
        case "p":
          return <p className={className}>{content}</p>;
      }
    }
  };

  return (
    <div className="container-fluid my-3" data-aos="fade-up">
      {renderContent()}
    </div>
  );
};

export default CustomContent;
