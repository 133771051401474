import React from "react";
import moment from "moment";
import { Link } from "@reach/router";

const CartRow = ({ cart }) => {
  const renderCustomer = () => {
    if (cart.customer && cart.customer !== null) {
      return (
        <Link to={`/myadmin/customer/${cart.customer_id}`}>
          <i className="fa fa-user" /> {cart.customer.name}{" "}
          {cart.customer.last_name}
        </Link>
      );
    }
    return cart.email;
  };

  const renderClassPackage = () => {
    if (cart.class_package && cart.class_package !== null) {
      return cart.class_package.title;
    }
  };

  return (
    <tr className="border-bottom">
      <td>#{cart.cart_id}</td>
      <td>{renderClassPackage()}</td>
      <td>{moment(cart.createdAt).format("DD MMM YYYY HH:mm")}</td>
      <td>{moment(cart.updatedAt).format("DD MMM YYYY HH:mm")}</td>
      <td>{renderCustomer()}</td>
    </tr>
  );
};

export default CartRow;
