import React, { useContext, useEffect } from "react";
import PanelTitle from "../../components/global/PanelTitle";
import PropTable from "../../components/props/PropTable";
import { PropsContext } from "../../context/PropsContext";
import { ModalContext } from "../../context/ModalContext";
import PropForm from "../../components/props/PropForm";
import ConfirmDeleteProp from "../../components/props/ConfirmDeleteProp";
import useUserRoles from "../../hooks/auth/useUserRoles";

const AdminProps = () => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { props, setProp, getProps, createProp } = useContext(PropsContext);

  const { isSuperAdmin } = useUserRoles();

  useEffect(() => {
    getProps();
  }, []);

  const handleCreateProp = () => {
    createProp();
    modalComponent("Agregar Prop", <PropForm handleCallback={getProps} />);
  };

  const handleEditProp = (prop) => {
    setProp(prop);
    modalComponent("Editar Prop", <PropForm handleCallback={getProps} />);
  };

  const handleDelete = (prop) => {
    setProp(prop);
    modalComponent(
      "Eliminar Prop",
      <ConfirmDeleteProp prop={prop} handleCancel={clearModal} />
    );
  };

  return (
    <div className="container-fluid">
      <PanelTitle
        title="Props"
        onClick={handleCreateProp}
        hideButton={!isSuperAdmin()}
      />

      <div className="card px-0 pt-0 pb-3 shadow">
        <PropTable
          props={props}
          handleEdit={handleEditProp}
          handleDelete={handleDelete}
        />
      </div>
      
    </div>
  );
};

export default AdminProps;
