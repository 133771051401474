import React, { useContext, useState } from "react";
import { ModalContext } from "../../context/ModalContext";

const RemoveClassesForm = ({ customer_id, removeClasses }) => {
  const [amount, setAmount] = useState(0);
  const { clearModal } = useContext(ModalContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    removeClasses(customer_id, amount);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label className="bold">Clases a Restar</label>
      <input
        type="number"
        className="form-control mb-3"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />

      <div className="row mt-3">
        <div className="col-6">
          <button 
            type="button" 
            className="btn btn-link text-secondary w-100"
            onClick={clearModal}
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn btn-primary w-100">
            Restar Clases
          </button>
        </div>
      </div>
    </form>
  );
};

export default RemoveClassesForm;
