import { useContext, useEffect, useState } from "react";
import { BranchesContext } from "../context/BranchesContext";
import { AuthContext } from "../context/AuthContext";
import { useLocation } from "@reach/router";
import { setupColor } from "../utils";
import api from "../services/api";

const useBranch = () => {
  const [currentInterceptor, setCurrentInterceptor] = useState(null);
  const { branch, branches, setBranch, getBranches, editBranchMode } =
    useContext(BranchesContext);
  const { user } = useContext(AuthContext);

  const location = useLocation();

  const branch_id = branch?.branch_id;

  const setBranchByLocalStorage = () => {
    const localBranch = window.localStorage.getItem("branch_id");
    let branchSet = false;

    if (localBranch && localBranch !== null) {
      selectBranch(localBranch);
      branchSet = true;
    }

    return branchSet;
  };

  const setBranchByUser = () => {
    let branchSet = false;

    if (
      user?.branch_id &&
      user?.branch_id !== null &&
      user?.branch_id !== undefined
    ) {
      selectBranch(user.branch_id);
      branchSet = true;
    }

    return branchSet;
  };

  const setDefaultBranch = () => {
    localStorage.setItem("branch_id", branches[0].branch_id);
    setBranch(branches[0]);
  };

  useEffect(() => {
    getBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (branch && branch !== null) {
      setupColor("primary", branch.primary_color);
      setupColor("primary-light", branch.light_color);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch]);

  useEffect(() => {
    if (Array.isArray(branches) && branch === null) {
      let alreadySet = setBranchByUser();
      if (!alreadySet) alreadySet = setBranchByLocalStorage();
      if (!alreadySet) setDefaultBranch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, branches]);

  const selectBranch = (branch_id) => {
    const selected = branches?.find(
      (branch) => String(branch.branch_id) === String(branch_id)
    );

    if (selected && selected !== null) {
      window.localStorage.setItem("branch_id", selected.branch_id);
      setupInterceptors(selected.branch_id);
      setBranch(selected);
    }
  };

  const setupInterceptors = (branch_id) => {
    if (currentInterceptor) api.interceptors.request.eject(currentInterceptor);

    const branchInterceptor = api.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        if (
          (config.method === "get" && !config.url.includes("branch_id")) ||
          (config.method === "delete" && !config.url.includes("branch_id"))
        ) {
          if (config.url.includes("?")) {
            config.url = config.url + `&branch_id=${branch_id}`;
          } else {
            config.url = config.url + `?branch_id=${branch_id}`;
          }
        }

        if (config.method === "post"){
          config.data.branch_id = branch_id;
        }

        if(!config.url.includes('branches')) {
          if (
            config.method === "put" && config.data.branch_id && !editBranchMode ||
            config.method === "put" && config.data.branch && !editBranchMode
          ){
            delete config.data.branch_id;
            delete config.data.branch;
          }
  
        }
       
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    setCurrentInterceptor(branchInterceptor);
  };

  return {
    branch,
    branches,
    setBranch,
    branch_id,
    getBranches,
    selectBranch,
  };
};

export default useBranch;
