import React from "react";
import { S3_ENDPOINT, getValue } from "../../utils";
import moment from "moment";

const CoachCard = ({ coach, editCoach, deleteCoach }) => {
  const { name, last_name } = coach;

  const renderBirthdate = () => {
    if(coach.birthdate) {
      const birthdate = moment(coach.birthdate).format('DD MMM YYYY');
      return birthdate;
    }
  }

  const renderImage = () => {
    if(coach.file?.name) {
      return(
        <img
          src={`${S3_ENDPOINT}/${coach.file?.name}.${coach.file?.type}`}
          className="me-1"
          width={40}
          height={40}
          alt="coach"
        />
      )
    }
  }

  return (
    <div className="card py-md-2 py-3 px-3 br-0 shadow-sm no-scale mb-md-0 mb-2">
      <div className="row small align-items-center" style={{fontSize: '14px'}}>
        <div className="col-12 col-md-2 pe-0">
          <p className="bold mb-0" style={{fontSize: '14px'}}>
            {renderImage()}
            {name} {last_name !== null ? last_name : ""}
          </p>
        </div>
        <div className="col-6 col-md-2">
          <a
            href={`https://instagram.com/${getValue(coach, "instagram")}`}
            className="text-primary"
            target="_blank"
          >
            <i className="fab fa-instagram me-2" />
            {getValue(coach, "instagram")}
          </a>
        </div>

        <div className="col-6 col-md-2">{renderBirthdate()}</div>

        <div className="col-12 col-md-2 text-center">
          <i className="fa fa-star text-warning show-mobile" />{" "}
          {getValue(coach, "video_stars_average")}
        </div>

        <div className="col-12 col-md-2 text-center">
          <i className="fa fa-certificate text-warning show-mobile" />{" "}
          {getValue(coach, "class_stars_average")}
        </div>
        
        <div className="col-12 col-md-2 mt-md-0 mt-2 text-center">
          <button
            className="btn btn-outline-dark me-2 btn-sm"
            onClick={() => editCoach(coach)}
          >
            <i className="fa fa-edit"></i>
            <span className="show-mobile ms-2">Editar</span>
          </button>
          <button
            className="btn btn-outline-danger btn-sm"
            onClick={() => deleteCoach(coach)}
          >
            <i className="fa fa-trash"></i>
            <span className="show-mobile ms-2">Eliminar</span>
          </button>
        </div>

      </div>
    </div>
  );
};

export default CoachCard;
