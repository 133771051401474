import React, { createContext, useContext, useReducer } from "react";
import InvoicesReducer from "../reducers/InvoicesReducer";
import InvoicesService from "../services/InvoicesService";
import {
  CLEAR_INVOICES,
  HIDE_SPINNER,
  INVOICES_RECIBIDOS,
  SHOW_SPINNER,
} from "../types";
import { ModalContext } from "./ModalContext";

const initialState = {
  invoices: null,
  spinner: false,
};

export const InvoicesContext = createContext(initialState);

export const InvoicesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(InvoicesReducer, initialState);

  const { alert } = useContext(ModalContext);

  const getInvoices = (start_date, end_date, filters) => {
    dispatch({ type: SHOW_SPINNER });
    dispatch({ type: CLEAR_INVOICES });
    InvoicesService.getInvoicesAdmin(start_date, end_date, filters)
      .then((res) => {
        const { invoices } = res.data;
        dispatch({ type: HIDE_SPINNER });
        dispatch({ type: INVOICES_RECIBIDOS, payload: invoices });
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  return (
    <InvoicesContext.Provider value={{ ...state, getInvoices }}>
      {children}
    </InvoicesContext.Provider>
  );
};
