
import {
  SET_BRANCH,
  CREATE_BRANCH,
  BRANCHES_RECEIVED,
  SET_PROPERTY_BRANCH,
  SET_BRANCH_FORM,
  SET_EDIT_BRANCH_MODE,
} from "../types/branches";

const schema = {
  name: '',
  description: ''
}

const branchesReducer = (state, { type, payload }) => {
  switch (type) {
    case BRANCHES_RECEIVED:
      return { ...state, branches: payload };
    case SET_BRANCH:
      return { ...state, branch: payload };
    case SET_EDIT_BRANCH_MODE:
      return { ...state, editBranchMode: payload };
    case SET_BRANCH_FORM:
      return { ...state, branchForm: payload };
    case CREATE_BRANCH:
      return { ...state, branchForm: schema };
    case SET_PROPERTY_BRANCH: {
      const { key, value } = payload;
      const branchForm = { ...state.branchForm };
      branchForm[key] = value;
      return { ...state, branchForm };
    }
    default:
      return { ...state};
  }
};

export default branchesReducer;
