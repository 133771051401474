import React from "react";
import { formatMonto } from "../../utils";

const SignupReasonRow = ({ reason }) => {
  return (
    <tr>
      <td>
        {reason.signup_reason === "" || reason.signup_reason === null
          ? "N/A"
          : reason.signup_reason}
      </td>
      <td>{reason.purchases}</td>
      <td>{reason.invoices}</td>
      <td>${formatMonto(reason.total)} MXN</td>
    </tr>
  );
};

export default SignupReasonRow;
 