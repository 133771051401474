import React, { useContext } from "react";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import { AnaliticasContext } from "../../context/AnaliticasContext";
import SignupReasonRow from "../../components/analytics/SignupReasonRow";

const AnaliticasSignupReasons = () => {
  const { signup_reasons, getSignupPurchases } = useContext(AnaliticasContext);

  const handleCallback = (start_date, end_date) => {
    getSignupPurchases({ start_date, end_date });
  };

  const renderSignupReasons = () => {
    if (Array.isArray(signup_reasons)) {
      if (signup_reasons.length === 0) {
        return <p className="small m-3">No hay compras registradas en las fechas elegidas.</p>;
      }
      return signup_reasons.map((reason) => (
        <SignupReasonRow key={reason.reason} reason={reason} />
      ));
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitleDate title="Razones de Inscripción" callback={handleCallback} />
      <div className="card shadow no-scale">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="bg-light bold p-2">
                <th>Razon</th>
                <th>Compras</th>
                <th>Cargos</th>
                <th>Ingresos</th>
              </tr>
            </thead>
            <tbody>{renderSignupReasons()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AnaliticasSignupReasons;
