import React, { useState } from "react";
import RatingCard from "./RatingCard";
import { useContext } from "react";
import { VideosContext } from "../../context/VideosContext";
import { useEffect } from "react";

const RatingComments = ({ video_id, ratings }) => {
  const [view, setView] = useState("all");

  const { video_ratings, getVideoRatings, clearVideoRatings } =
    useContext(VideosContext);

  useEffect(() => {
    if (!isNaN(parseInt(video_id))) {
      getVideoRatings({ video_id });
    }
    return clearVideoRatings;
  }, [video_id]);

  const renderComments = () => {
    if (!Array.isArray(ratings)) {
      ratings = video_ratings;
    }
    if (Array.isArray(ratings)) {
      if (view === "comment") {
        ratings = ratings.filter(
          ({ content, comment }) =>
            (content && content !== null) || (comment && comment !== null)
        );
      } else if (view === "no-comment") {
        ratings = ratings.filter(
          ({ content, comment }) => content === null || comment === null
        );
      }
      return ratings.map((rating, index) => (
        <RatingCard key={index} rating={rating} />
      ));
    }
  };
  return (
    <div className="container-fliud">
      <h3 className="mb-0 border-bottom pb-2">Calificaciones</h3>
      <div className="btn-group py-3">
        <button
          onClick={() => setView("all")}
          className={`btn btn-${
            view === "all" ? "primary" : "outline-primary"
          }`}
        >
          Todos
        </button>
        <button
          onClick={() => setView("comment")}
          className={`btn btn-${
            view === "comment" ? "primary" : "outline-primary"
          }`}
        >
          Comentario
        </button>
        <button
          onClick={() => setView("no-comment")}
          className={`btn btn-${
            view === "no-comment" ? "primary" : "outline-primary"
          }`}
        >
          Sin Comentario
        </button>
      </div>
      {renderComments()}
    </div>
  );
};

export default RatingComments;
