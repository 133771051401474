import React, { useContext, useEffect } from "react";
import { VideosContext } from "../../context/VideosContext";
import { PropsContext } from "../../context/PropsContext";
import VideoPropFormRow from "./VideoPropFormRow";

const VideoPropSelect = () => {
  const { video, setPropiedadVideo } = useContext(VideosContext);
  const { props, getProps } = useContext(PropsContext);

  useEffect(() => {
    getProps();
  }, []);

  const handleChangeVideoProp = (prop_id, key, value) => {
    const video_props = [...video.video_props];
    let index = video_props.findIndex((prop) => prop.prop_id === prop_id);
    if (index === -1) {
      const current_prop = props.find((prop) => prop.prop_id === prop_id);
      video_props.push(current_prop);
      index = video_props.findIndex((prop) => prop.prop_id === prop_id);
    }
    // Add or remove prop
    if (key === "checked") {
      if (!value && index !== -1) video_props.splice(index, 1);
      // Modify value of an existing prop
    } else {
      const current_video_prop = video_props[index];
      current_video_prop[key] = value;
    }
    setPropiedadVideo("video_props", video_props);
  };

  const renderProps = () => {
    if (Array.isArray(props) && video !== null) {
      return props.map((prop) => (
        <VideoPropFormRow
          prop={prop}
          video={video}
          key={prop.prop_id}
          modifier={handleChangeVideoProp}
        />
      ));
    }
  };
  return (
    <div className="mb-3">
      <h3>Props</h3>
      <div className="row bg-light py-2 align-items-center border small bold">
        <div className="col-1"></div>
        <div className="col-7">Nombre</div>
        <div className="col-2">Cantidad</div>
        <div className="col-2">Requerido</div>
      </div>
      {renderProps()}
    </div>
  );
};

export default VideoPropSelect;
