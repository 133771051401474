import React, { useContext } from "react";
import { BranchesContext } from "../../context/BranchesContext";

const BranchForm = ({ modifier, postBranch, handleCancel }) => {
  const { branchForm } = useContext(BranchesContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    postBranch(branchForm);
  };

  const renderForm = () => {
    if (branchForm && branchForm !== null) {
      const { name, description } = branchForm;
      return (
        <form onSubmit={handleSubmit}>
          <label>Nombre</label>
          <input
            type="text"
            className="form-control mb-3"
            value={name}
            onChange={(e) => modifier("name", e.target.value)}
          />
          <label>Descripción</label>
          <input
            type="text"
            className="form-control"
            value={description}
            onChange={(e) => modifier("description", e.target.value)}
          />
          <div className="row mx-auto mt-3">
            
            <div className="col-6 ps-0">
              <button
                type="button"
                className="btn w-100 text-mute"
                onClick={handleCancel}
              >
                CANCELAR
              </button>
            </div>

            <div className="col-6 pe-0">
              <button
                type="submit"
                className="btn btn-primary w-100"
              >
                Guardar
              </button>
            </div>

          </div>
        </form>
      );
    }
  };

  return <div className="container-fluid px-0">{renderForm()}</div>;
};

export default BranchForm;
