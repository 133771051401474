import React, { useState } from "react";
import moment from "moment";
import utils from "./utils";
import ScheduleAdminClass from "./ScheduleAdminClass";
import MonthScheduleAdminDay from "./MonthScheduleAdminDay";

const MonthScheduleAdminWeek = ({
  week,
  location,
  filtered,
  handleCallback,
}) => {
  const [day, setDay] = useState(null);

  const getDetails = (day) => {
    const details =
      location !== null && location !== "" && location
        ? day.details.filter(
            (detail) => parseInt(detail.location_id) === parseInt(location)
          )
        : day.details;
    const sorted = utils.getSortedClasses(details);
    return sorted;
  };

  const renderSingleClasses = () => {
    if (day !== null) {
      return (
        <div className="schedule-classes-container shadow-sm">
          <h4 className="mb-1 pb-1 border-bottom">
            {moment(day.date).format("MMM DD")}
          </h4>
          <div className="row mx-0">
            {getDetails(day).map((clase) => (
              <ScheduleAdminClass
                key={clase.single_class_id}
                handleCallback={handleCallback}
                singleClass={clase}
              />
            ))}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="row">
      {week.map((currentDay, index) => (
        <MonthScheduleAdminDay
          key={index}
          day={currentDay}
          selectedDay={day}
          clases={getDetails(currentDay).length}
          location={location}
          filtered={filtered}
          setDay={() => {
            if (day !== null) {
              if (day.date === currentDay.date) {
                return setDay(null);
              }
            }
            setDay(currentDay);
          }}
        />
      ))}
      <div className="container-fluid px-0 float-left">
        {renderSingleClasses()}
      </div>
    </div>
  );
};

export default MonthScheduleAdminWeek;
