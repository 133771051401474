import React from "react";
import { formatMonto } from "../../utils";

const Bonus = ({
  number,
  name,
  title,
  value,
  content_1,
  content_2,
  content_3,
  tagline,
  color,
  image_source,
}) => {
  return (
    <div className={`container-fluid p-5 text-center bonus`} data-aos="fade-up">
      <div className={`bg-${color} p-3 text-white`}>
        <h4 className="h2 mb-0">
          <b>BONO #{number}</b> - {name}
        </h4>
      </div>
      <div className={`border border-2 border-solid border-${color} p-4`}>
        <h3>
          <b>{title}</b>
        </h3>
        <h3 className={`h2 text-${color}`}>
          <b>
            Valor: {"$"}
            {formatMonto(value)}
          </b>
        </h3>
        <img src={image_source} className="mw-100 w-75 m-auto d-block my-5" />
        <p>{content_1}</p>
        <p>{content_2}</p>
        <p>{content_3}</p>
        <h4 className="bold">
          <b>{tagline}</b>
        </h4>
      </div>
    </div>
  );
};

export default Bonus;
