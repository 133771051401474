import React, { useContext, useState } from "react";
import VideoRatingCard from "../../components/videos/VideoRatingCard";
import RatingsHeader from "../../components/ratings/RatingsHeader";
import { VideosContext } from "../../context/VideosContext";

const AdminVideoRatings = () => {
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  
  const { video_ratings, getVideoRatings } = useContext(VideosContext);

  const fetchRatings = (params) => {
    const { start_date, end_date } = params;
    setEndDate(end_date);
    setStartDate(start_date);

    getVideoRatings(params);
  };

  const handleCallback = () => {
    getVideoRatings({ start_date: startDate, end_date: endDate });
  };

  const renderVideoRatings = () => {
    if (Array.isArray(video_ratings)) {
      if (video_ratings.length === 0) {
        return <p>No hay calificaciones disponibles.</p>;
      }
      return video_ratings.map((rating, index) => (
        <div key={index} className="col-12 col-md-6 col-xl-4">
          <VideoRatingCard
            video_rating={rating}
            handleCallback={handleCallback}
          />
        </div>
      ));
    }
  };

  return (
    <div className="container-fluid" style={{ overflowY: "scroll" }}>
      <RatingsHeader title="Videos" fetchRatings={fetchRatings} />
      <div className="row">{renderVideoRatings()}</div>
    </div>
  );
};

export default AdminVideoRatings;
