import { Link } from "@reach/router";
import React, { useState, useContext } from "react";
import FilterSection from "../../components/videos/FilterSection";
import VideoCard from "../../components/videos/VideoCard";
import { VideosContext } from "../../context/VideosContext";
import OffCanvas from "../../components/global/OffCanvas";
import { useEffect } from "react";
import RatingComments from "../../components/ratings/RatingComments";

const AdminVideos = () => {
  const [page, setPage] = useState(1);
  const [showOffCanvas, setShowOffCanvas] = useState(false);

  const { videos, video_ratings, getVideoRatings, clearVideoRatings } =
    useContext(VideosContext);

  useEffect(() => {
    if (Array.isArray(video_ratings)) {
      setShowOffCanvas(true);
    } else {
      setShowOffCanvas(false);
    }
  }, [video_ratings]);

  useEffect(() => {
    if (!showOffCanvas) {
      clearVideoRatings();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOffCanvas]);

  const fetchComments = (video_id) => {
    getVideoRatings({ video_id });
  };

  const renderComments = () => {
    if (Array.isArray(video_ratings)) {
      return <RatingComments ratings={video_ratings} />;
    }
  };

  const renderVideos = () => {
    if (videos && videos !== null) {
      let videosRender = videos;
      return videosRender.map((video) => (
        <VideoCard
          handleComments={fetchComments}
          key={video.video_id}
          video={video}
          disableHover
          editable
        />
      ));
    }
    return <div className="spinner-border mx-3"></div>;
  };

  const renderPages = () => {
    return (
      <div>
        <div
          className="btn btn-light shadow-sm mx-2 border"
          disabled={page === 1}
          onClick={() => {
            if (page > 1) {
              setPage(page - 1);
            }
          }}
        >
          {"<"}
        </div>
        <span className="mx-2">{page}</span>
        <div
          className="btn btn-light shadow-sm mx-2 border"
          onClick={() => setPage(page + 1)}
        >
          {">"}
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row border-bottom mb-3 align-items-center">
        <div className="col col-md-6">
          <h1>Videos</h1>
        </div>
        <div className="col col-md-6 text-end">
          <Link
            to="/myadmin/videos/ratings"
            className="btn btn-outline-primary me-2"
          >
            <i className="fa fa-star me-1" /> Calificaciones
          </Link>
          <Link to="/myadmin/videos/nuevo" className="btn btn-primary">
            + Agregar
          </Link>
        </div>
      </div>
      <FilterSection page={page} />
      <div className="row">{renderVideos()}</div>
      <div className="container-fluid text-right px-0">{renderPages()}</div>
      <OffCanvas
        showOffCanvas={showOffCanvas}
        setShowOffCanvas={setShowOffCanvas}
      >
        {renderComments()}
      </OffCanvas>
    </div>
  );
};

export default AdminVideos;
