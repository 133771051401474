import React from "react";
import MonthScheduleAdminWeek from "./MonthScheduleAdminWeek";

const MonthScheduleAdmin = ({ monthClasses, handleCallback }) => {
  const renderDays = () => {
    if (Array.isArray(monthClasses)) {
      const weeks = Math.ceil(monthClasses.length / 7);
      return new Array(weeks)
        .fill(1)
        .map((one, index) => (
          <MonthScheduleAdminWeek
            key={index}
            handleCallback={handleCallback}
            week={monthClasses.slice(index * 7, index * 7 + 7)}
          />
        ));
    }
  };

  return (
    <div className="container-fluid schedule-container">{renderDays()}</div>
  );
};

export default MonthScheduleAdmin;
