import React, { useContext, useState } from "react";
import { AnaliticasContext } from "../../context/AnaliticasContext";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import CustomersTable from "../../components/customers/CustomersTable";

const AnaliticasInscritos = () => {
  const [viewCustomers, setViewCustomers] = useState(null);
  const { available_classes, cancelled_customers, signups, available_purchasers, purchasers, getInscritos } =
    useContext(AnaliticasContext);

    const renderCustomers = () => {
      if (viewCustomers !== null) {
        let renderCustomers = [];
        let customerLabel = "";
        switch (viewCustomers) {
          case "signups":
            renderCustomers = signups;
            customerLabel = "Usuarios Nuevos";
            break;
          case "available_classes":
            renderCustomers = available_classes;
            customerLabel = "Clientes con Clases Disponibles";
            break;
          case "available_purchasers":
            renderCustomers = available_purchasers;
            customerLabel = "Clientes Nuevos con Clases Disponibles";
            break;
          case "cancelled_customers":
            renderCustomers = cancelled_customers;
            customerLabel = "Compras Canceladas";
            break;
          default:
            renderCustomers = purchasers;
            customerLabel = "Clientes Nuevos";
        }
        return (
          <div className="card p-3 no-scale">
            <div className="row align-items-center mb-2">
              <div className="col-12 col-md-6">
                <h3 className="mb-0">{customerLabel}</h3>
              </div>
              <div className="col-12 col-md-6 text-right">
                <button
                  className="btn btn-link text-secondary"
                  onClick={() => setViewCustomers(null)}
                >
                  Ocultar
                </button>
              </div>
            </div>
            <CustomersTable
              customers={renderCustomers}
              extraFields={[{ label: "Disponibles", key: "available_classes" }]}
              showCancelReason={viewCustomers === "cancelled_customers"}
            />
          </div>
        );
      }
    };
  

  const renderAmount = (data) => {
    if (Array.isArray(data)) {
      return data.length;
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid px-3 mb-3">
      <PanelTitleDate title="Inscritos" callback={getInscritos} />
      <div className="row mt-4">
        <div className="col-12 col-md-3 my-2">
          <div className="card p-3 no-scale ">
            <h3 className="h5 bold">Clientes con Clases</h3>
            <h3 className="mb-0">{renderAmount(available_classes)}</h3>
            <button
              className="btn btn-link text-secondary text-left px-0 my-2"
              onClick={() => setViewCustomers("available_classes")}
            >
              Expandir
            </button>
          </div>
        </div>
        <div className="col-12 col-md-3 my-2">
          <div className="card p-3 no-scale">
            <h3 className="h5 bold">Usuarios Nuevos</h3>
            <h3 className="mb-0">{renderAmount(signups)}</h3>
            <button
              className="btn btn-link text-secondary text-left px-0 my-2"
              onClick={() => setViewCustomers("signups")}
            >
              Expandir
            </button>
          </div>
        </div>
        <div className="col-12 col-md-3 my-2">
          <div className="card p-3 no-scale">
            <h3 className="h5 bold">Compras Realizadas</h3>
            <h3 className="mb-0">{renderAmount(purchasers)}</h3>
            <button
              className="btn btn-link text-secondary text-left px-0 my-2"
              onClick={() => setViewCustomers("purchasers")}
            >
              Expandir
            </button>
          </div>
        </div>
        <div className="col-12 col-md-3 my-2">
          <div className="card p-3 no-scale">
            <h3 className="h5 bold">Usuarios con Compras</h3>
            <h3 className="mb-0">{renderAmount(available_purchasers)}</h3>
            <button
              className="btn btn-link text-secondary text-left px-0 my-2"
              onClick={() => setViewCustomers("available_purchasers")}
            >
              Expandir
            </button>
          </div>
        </div>
        <div className="col-12 col-md-3 my-2">
          <div className="card p-3 no-scale">
            <h3 className="h5 bold">Compras Canceladas este mes</h3>
            <h3 className="mb-0">{renderAmount(cancelled_customers)}</h3>
            <button
              className="btn btn-link text-secondary text-left px-0 my-2"
              onClick={() => setViewCustomers("cancelled_customers")}
            >
              Expandir
            </button>
          </div>
        </div>
      </div>
      {renderCustomers()}
    </div>
  );
};

export default AnaliticasInscritos;
