import React, { useContext } from "react";
import { AnaliticasContext } from "../../context/AnaliticasContext";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import DiscountsTable from "../../components/descuentos/DiscountsTable";

const AnaliticasDescuentoCoach = () => {
  const { discounts, getDescuentosCoach } = useContext(AnaliticasContext);

  const fetchDescuentos = (start_date, end_date) => {
    getDescuentosCoach({ start_date, end_date });
  };

  return (
    <div className="container-fluid">
      <PanelTitleDate
        title="Analíticas Descuentos"
        callback={fetchDescuentos}
      />
      <div className="card shadow">
        <DiscountsTable hideSales discounts={discounts} showCommissions />
      </div>
    </div>
  );
};

export default AnaliticasDescuentoCoach;
