import React, { useContext } from "react";
import { MusclesContext } from "../../context/MusclesContext";

const ConfirmDeleteMuscle = ({ prop, handleCancel }) => {
  const { deleteMuscle } = useContext(MusclesContext);

  const handleDelete = () => {
    deleteMuscle(prop);
  };

  return (
    <div>
      <p>¿Estás seguro que deseas eliminar el prop {prop.name}?</p>
      <p>Esta acción NO puede deshacerse.</p>
      <div className="row">
        <div className="col-6">
          <button onClick={handleCancel} className="btn text-muted w-100">
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button onClick={handleDelete} className="btn btn-danger w-100">
            Eliminar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteMuscle;
