import React, { useState } from "react";
import OnlineLeaderboardRow from "./OnlineLeaderboardRow";

const OnlineLeaderboardTable = ({ query, customers, start_date, end_date }) => {
  const [sort, setSort] = useState("playbacks");

  const renderCustomers = () => {
    if (Array.isArray(customers)) {
      let customersRender = [...customers];
      customersRender = customersRender.sort((a, b) =>
        parseInt(a[sort]) > parseInt(b[sort]) ? -1 : 1
      );
      if (query && query !== "") {
        customersRender = customersRender.filter(
          ({ name, last_name, email }) =>
            String(name).includes(query) ||
            String(last_name).includes(query) ||
            String(email).includes(query)
        );
      }
      return customersRender.map((customer) => (
        <OnlineLeaderboardRow
          key={customer.customer_id}
          start_date={start_date}
          end_date={end_date}
          customer={customer}
        />
      ));
    }
  };

  return (
    <div className="table-responsive mb-3">
      <table className="leaderboard table">
        <thead>
          <tr className="bg-light bold p-2">
            <th>Nombre</th>
            <th>Instagram</th>
            <th>Ciudad</th>
            <th
              onClick={() => setSort("playbacks")}
              className={sort === "playbacks" ? "bg-primary text-white" : ""}
            >
              Reproducciones{" "}
              <i
                className={`fa fa-chevron-${
                  sort === "playbacks" ? "down" : "up"
                }`}
              ></i>
            </th>
            <th>Promedio Diario</th>
            <th
              onClick={() => setSort("streaks")}
              className={sort === "streaks" ? "bg-primary text-white" : ""}
            >
              Streaks{" "}
              <i
                className={`fa fa-chevron-${
                  sort === "streaks" ? "down" : "up"
                }`}
              ></i>
            </th>
          </tr>
        </thead>
        <tbody>{renderCustomers()}</tbody>
      </table>
    </div>
  );
};

export default OnlineLeaderboardTable;
