
import {
  SET_THREAD,
  CREATE_THREAD,
  THREADS_RECEIVED,
  SET_PROPERTY_THREAD,
} from "../types/threads";

const schema = {

}

const threadsReducer = (state, { type, payload }) => {
  switch (type) {
    case THREADS_RECEIVED:
      return { ...state, threads: payload };
    case SET_THREAD:
      return { ...state, thread: payload };
    case CREATE_THREAD:
      return { ...state, thread: schema };
    case SET_PROPERTY_THREAD: {
      const { key, value } = payload;
      const thread = { ...state.thread };
      thread[key] = value;
      return { ...state, thread };
    }
    default:
      return { ...state};
  }
};

export default threadsReducer;
