import React, { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import { formatMonto, getTotalCompras } from "../../utils";
import { ModalContext } from "../../context/ModalContext";
import moment from "moment";
import AddClassesForm from "../purchases/AddClassesForm";
import RemoveClassesForm from "../clases/RemoveClassesForm";
import { AuthContext } from "../../context/AuthContext";

const CustomerActions = ({ customer, handleNotesOffcanvas }) => {
  const [copied, setCopied] = useState(false);
  const { recoverPassword } = useContext(AuthContext);

  const { 
    link, 
    addCustomerClasses, 
    removeCustomerClasses,
    clearLink, 
    getPasswordResetLink 
  } = useContext(CustomerContext);


  const { success, modalComponent } = useContext(ModalContext);
  const { customer_id } = customer;

  useEffect(() => {
    if (copied) {
      success("Enlace copiado al portapapeles.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copied]);

  useEffect(() => {
    clearLink();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const renderLink = () => {
    if (link && link !== null) {
      return (
        <div className="row my-3 mx-0">
          <div className="col-10 px-0">
            <input disabled type="text" value={link} className="form-control" />
          </div>
          <div className="col-2 px-0">
            <button
              onClick={() => {
                window.navigator.clipboard
                  .writeText(link)
                  .then(() => setCopied(true));
              }}
              className="btn w-100 border"
            >
              <i className="fa fa-copy" />
            </button>
          </div>
        </div>
      );
    }
  };

  const confirmRemoveClasses = () => {
    modalComponent(
      "Restar Clases",
      <RemoveClassesForm
        customer_id={customer_id}
        removeClasses={removeCustomerClasses}
      />
    );
  };

  const confirmAddClasses = () => {
    modalComponent(
      "Agregar Clases",
      <AddClassesForm
        customer_id={customer_id}
        addClasses={addCustomerClasses}
      />
    );
  };

  const renderCustomerClasses = () => {
    if (Array.isArray(customer.available_classes)) {
      return customer.available_classes.length;
    }
  };

  const renderExpiration = () => {
    if (customer.has_online && customer.user === null) {
      return (
        <span>
          {" "}
          hasta {moment(customer.online_expiration).format("DD MMM YYYY")}
        </span>
      );
    }
  };

  const renderOnlineAccess = () => {
    if (customer.has_online !== undefined) {
      return (
        <div className="row mb-3">
          <div className="col-6">Acceso a Clases Online</div>
          <div className="col-6">
            {customer.has_online ? (
              <span className="badge badge-pill bg-success">
                Activo{renderExpiration()}
              </span>
            ) : (
              <span className="badge badge-pill bg-secondary">Inactivo</span>
            )}
          </div>
        </div>
      );
    }
  };

  const renderBranch = () => {
    if(customer.branch) {
      return (
        <div className="row mb-3">
          <div className="col-6">Franquicia</div>
          <div className="col-6">
            <span 
              className="badge badge-pill"
              style={{
                backgroundColor: customer.branch.primary_color
              }}
            >
              {customer.branch.name}
            </span>
          </div>
        </div>
      )
    }
  }


  const renderNotesBtn = () => {
    if(typeof handleNotesOffcanvas === 'function') {
      return(
        <button 
          type="button"
          className={`btn btn-primary show-mobile btn-sm`}
          onClick={() => {
            handleNotesOffcanvas();
          }}
        >
          <i className="fas fa-edit me-2"/>
          Notas
        </button>
      );
    }
  }

  return (
    <div className="container-fluid px-0">
      <h4>Reestablecer Contraseña</h4>

      {customer.uid !== null ? (
        <div className="row">
          <div className="col-6">
            <button
              className="btn w-100 btn-outline-dark me-2 my-1"
              onClick={() => recoverPassword(customer.email)}
            >
              <i className="fa fa-envelope me-2"></i> Correo
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn w-100 btn-outline-dark me-2 my-1"
              onClick={() => getPasswordResetLink(customer.email)}
            >
              <i className="fa fa-link me-2"></i> Enlace
            </button>
          </div>
          <div className="container-fluid">{renderLink()}</div>
        </div>
      ) : (
        <p className="text-danger">
          Cuenta de plataforma anterior. Debe iniciar sesión el usuario
          desde el portal de clientes y activar su cuenta.
        </p>
      )}
      
      <h4 className="mt-4">
        Compras Totales: {"$"}
        {formatMonto(getTotalCompras(customer))} MXN
      </h4>
        <div className="row mb-3">
          <div className="col-6 col-md-3 my-2">
            <span className="bold">Disponibles:</span>{" "}
          </div>
          <div className="col-6 col-md-3 my-2">{renderCustomerClasses()}</div>
          <div className="col-12 col-md-6 my-2">
            <button
              className="btn btn-outline-success btn-sm me-3"
              onClick={confirmAddClasses}
            >
              <i className="fa fa-plus"></i> Clases
            </button>
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={confirmRemoveClasses}
            >
              <i className="fa fa-minus"></i> Clases
            </button>
          </div>
        </div>
      {renderOnlineAccess()}
      {renderBranch()}
      {renderNotesBtn()}

    </div>
  );
};

export default CustomerActions;
