import React, { useState, useEffect } from "react";
import { formatMonto } from "../../utils";

const FreeTrialStats = ({ free_trials }) => {
  const [total, setTotal] = useState(null);
  const [converted, setConverted] = useState(null);
  const [newCustomers, setNewCustomers] = useState(null);
  const [totalRevenue, setTotalRevenue] = useState(null);
  const [conversionRate, setConversionRate] = useState(null);

  useEffect(() => {
    if (Array.isArray(free_trials)) {
      getData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [free_trials]);

  useEffect(() => {
    if (total !== null && total > 0 && converted !== null) {
      getConversionRate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, converted]);

  const getData = () => {
    getConverted();
    getFreeTrials();
    getNewCustomers();
    getConversionRate();
  };

  const getFreeTrials = () => {
    setTotal(free_trials.length);
  };

  const getConversionRate = () => {
    setConversionRate(parseFloat(converted / total * 100).toFixed(2));
  };

  const getNewCustomers = () => {
    let total = 0;
    free_trials.forEach((purchase) => {
      const { customer } = purchase;
      if (customer && customer !== null) {
        if (customer.purchases.length === 1) total++;
      }
    });
    setNewCustomers(total);
  };

  const getConverted = () => {
    let totalInvoices = 0;
    let revenue = 0;
    free_trials.forEach((purchase) => {
      const { invoices } = purchase;
      if (invoices.length > 1) {
        totalInvoices++;
        revenue += invoices.reduce(
          (a, b) => parseFloat(a.total_payment) + parseFloat(b.total_payment)
        );
      }
    });
    setConverted(totalInvoices);
    setTotalRevenue(revenue);
  };

  return (
    <div className="row mb-4">
      <div className="col-6 col-lg-3">
        <div className="card p-3 shadow">
          <h3>{total}</h3>
          <p className="mb-0">Free Trials</p>
        </div>
      </div>
      <div className="col-6 col-lg-3">
        <div className="card p-3 shadow">
          <h3>{newCustomers}</h3>
          <p className="mb-0">Clientes Nuevos</p>
        </div>
      </div>
      <div className="col-6 col-lg-3">
        <div className="card p-3 shadow">
          <h3>
            {converted} ({conversionRate}%)
          </h3>
          <p className="mb-0">Convertidas</p>
        </div>
      </div>
      <div className="col-6 col-lg-3">
        <div className="card p-3 shadow">
          <h3>${formatMonto(totalRevenue)}</h3>
          <p className="mb-0">Ingresos</p>
        </div>
      </div>
    </div>
  );
};
export default FreeTrialStats;
