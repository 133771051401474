import React, { useContext, useState } from "react";
import { AnaliticasContext } from "../../context/AnaliticasContext";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import LeaderboardTable from "../../components/analytics/LeaderboardTable";

const AnaliticasLeaderboards = () => {
  const [query, setQuery] = useState("");
  const { leaderboard, getLeaderboard } = useContext(AnaliticasContext);

  const fetchLeaderboard = (start_date, end_date) => {
    getLeaderboard({ start_date, end_date });
  };

  const renderLeaderboard = () => {
    if (Array.isArray(leaderboard)) {
      if (leaderboard.length === 0) {
        return <p>No hay reservaciones registradas.</p>;
      }
      return <LeaderboardTable query={query} customers={leaderboard} />;
    }
  };

  const renderCount = () => {
    if (Array.isArray(leaderboard)) {
      return leaderboard.length;
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitleDate title="Presenciales" callback={fetchLeaderboard} />
      <div className="card shadow">
        <div className="row align-items-center">
          <div className="col-12 col-md-8 mb-3">
            <input
              type="text"
              value={query}
              className="form-control"
              placeholder="Buscar por nombre o correo..."
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <div className="col-12 col-md-4 mb-3 text-md-end text-start">
            <h4 className="mb-0">Alumnas: {renderCount()}</h4>
          </div>
        </div>
        {renderLeaderboard()}
      </div>
    </div>
  );
};

export default AnaliticasLeaderboards;
