import React, { useState } from "react";

const ReportPost = ({ handleCancel, handleReport }) => {
  const [comment, setComment] = useState("");
  return (
    <div>
      <p>¿Estás segura que deseas reportar este post?</p>
      <label>Motivo para Reportar</label>
      <input
        type="text"
        value={comment}
        className="form-control mb-3"
        onChange={(e) => setComment(e.target.value)}
      />
      <div className="row">
        <div className="col-6">
          <button onClick={handleCancel} className="btn text-muted w-100">
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button
            onClick={() => handleReport(comment)}
            className="btn w-100 btn-danger"
          >
            Reportar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportPost;
