import React, { useContext } from "react";
import { getValue } from "../../utils";
import { AvailableClassesContext } from "../../context/AvailableClassesContext";

const AvailableClassForm = ({ handleCancel, handleCallback, editMany, items }) => {
  const { available_class, saveAvailableClass, setPropertyAvailableClass, saveManyAvailableClasses } =
    useContext(AvailableClassesContext);

  const handleSubmit = (e) => {
    e.preventDefault();

    if(editMany) {
      const data = {
        start_date: available_class.start_date,
        expiration_date: available_class.expiration_date,
        items
      }
      saveManyAvailableClasses(data, handleCallback);
    } else {
      saveAvailableClass(available_class, handleCallback);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Fecha de Inicio</label>
      <input
        type="date"
        className="form-control mb-3"
        value={getValue(available_class, "start_date", "date")}
        onChange={(e) =>
          setPropertyAvailableClass("start_date", e.target.value)
        }
      />
      <label>Fecha de Expiración</label>
      <input
        type="date"
        className="form-control mb-3"
        value={getValue(available_class, "expiration_date", "date")}
        onChange={(e) =>
          setPropertyAvailableClass("expiration_date", e.target.value)
        }
      />
      <div className="row">
        <div className="col">
          <button
            type="button"
            onClick={handleCancel}
            className="btn w-100 text-muted"
          >
            Cancelar
          </button>
        </div>
        <div className="col">
          <button type="submit" className="btn w-100 btn-primary">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default AvailableClassForm;
