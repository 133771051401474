
import React, { createContext, useReducer, useContext } from 'react';
import RefundsService from '../services/RefundsService';
import RefundsReducer from '../reducers/RefundsReducer';
import {
  REFUNDS_RECEIVED,
  SET_REFUND,
  CREATE_REFUND,
  SET_PROPERTY_REFUND,
} from "../types/refunds";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  loading: false,
  refunds: null,
  refund: {
    reason: '',
    amount_refunded: 0,
    purchase_id: null,
    customer_id: null
  },
};

export const RefundsContext = createContext(initialState);

export const RefundsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(RefundsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getRefunds = () => {
    RefundsService.getRefunds()
      .then((response) => {
        const { refunds } = response.data;
        dispatch({ type: REFUNDS_RECEIVED, payload: refunds });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleRefund = (refund_id) => {
    RefundsService.getSingleRefund(refund_id)
      .then((response) => {
        const { refund } = response.data;
        dispatch({ type: SET_REFUND, payload: refund });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setRefund = (refund) => {
    dispatch({ type: SET_REFUND, payload: refund });
  };

  const createRefund = () => {
    dispatch({ type: CREATE_REFUND });
  };

  const setPropertyRefund = (key, value) => {
    dispatch({ type: SET_PROPERTY_REFUND, payload: { key, value } });
  };

  const saveRefund = (refund, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = RefundsService.putRefund;
      if(isNaN(parseInt(refund.refund_id))) {
        service = RefundsService.postRefund;
      }
      service(refund).then(() => {
        success("Reembolso generado.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const saveSubscriptionRefund = (refund, callback) => {
    dispatch({ type: SHOW_SPINNER });
   
    RefundsService.postSubscriptionRefund(refund).then(() => {
      success("Reembolso generado.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    })
    .catch((error) => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    });
  };

  const deleteRefund = (refund_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    RefundsService.deleteRefund(refund_id).then(() => {
      success("Refund deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <RefundsContext.Provider
    value={{
      ...state,
      setRefund,
      getRefunds,
      saveRefund,
      saveSubscriptionRefund,
      deleteRefund,
      createRefund,
      getSingleRefund,
      setPropertyRefund,
    }}
  >
    {children}
  </RefundsContext.Provider>
);
};
