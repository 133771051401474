
import React, { createContext, useReducer, useContext } from 'react';
import ThreadsService from '../services/ThreadsService';
import ThreadsReducer from '../reducers/ThreadsReducer';
import {
  THREADS_RECEIVED,
  SET_THREAD,
  CREATE_THREAD,
  SET_PROPERTY_THREAD,
} from "../types/threads";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  threads: null,
  thread: null,
};

export const ThreadsContext = createContext(initialState);

export const ThreadsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ThreadsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getThreads = () => {
    ThreadsService.getThreads()
      .then((response) => {
        const { threads } = response.data;
        dispatch({ type: THREADS_RECEIVED, payload: threads });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleThread = (thread_id) => {
    ThreadsService.getSingleThread(thread_id)
      .then((response) => {
        const { thread } = response.data;
        dispatch({ type: SET_THREAD, payload: thread });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setThread = (thread) => {
    dispatch({ type: SET_THREAD, payload: thread });
  };

  const createThread = () => {
    dispatch({ type: CREATE_THREAD });
  };

  const setPropertyThread = (key, value) => {
    dispatch({ type: SET_PROPERTY_THREAD, payload: { key, value } });
  };

  const saveThread = (thread, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = ThreadsService.putThread;
      if(isNaN(parseInt(thread.thread_id))) {
        service = ThreadsService.postThread;
      }
      service(thread).then(() => {
        success("Thread saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteThread = (thread_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    ThreadsService.deleteThread(thread_id).then(() => {
      success("Thread deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <ThreadsContext.Provider
    value={{
      ...state,
      setThread,
      getThreads,
      saveThread,
      deleteThread,
      createThread,
      getSingleThread,
      setPropertyThread,

    }}
  >
    {children}
  </ThreadsContext.Provider>
);
};
