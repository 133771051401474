
import {
  SET_MUSCLE,
  CREATE_MUSCLE,
  MUSCLES_RECEIVED,
  SET_PROPERTY_MUSCLE,
} from "../types/muscles";

const schema = {

}

const musclesReducer = (state, { type, payload }) => {
  switch (type) {
    case MUSCLES_RECEIVED:
      return { ...state, muscles: payload };
    case SET_MUSCLE:
      return { ...state, muscle: payload };
    case CREATE_MUSCLE:
      return { ...state, muscle: schema };
    case SET_PROPERTY_MUSCLE: {
      const { key, value } = payload;
      const muscle = { ...state.muscle };
      muscle[key] = value;
      return { ...state, muscle };
    }
    default:
      return { ...state};
  }
};

export default musclesReducer;
