import React from "react";
import { formatMonto } from "../../utils";
import moment from "moment";

const RatingCard = ({ rating }) => {
  const renderPublic = () => {
    if (rating.post !== null) {
      return (
        <span>
          <i className="fa fa-users text-primary"></i>
        </span>
      );
    }
  };

  const renderComment = () => {
    if (rating.comment && rating.comment !== null) {
      return <p>{rating.comment}</p>;
    }
    if (rating.content && rating.content !== null) {
      return <p>{rating.content}</p>;
    }
    return <p className="small text-muted">Sin Comentario</p>;
  };
  return (
    <div className="mb-3">
      <span className="small">{rating.customer.name}:</span>
      <div className="bg-light p-3">
        {renderComment()}
        <div className="row">
          <div className="col-6">
            <span className="me-3">
              {formatMonto(rating.class_stars)}{" "}
              <i className="fa fa-star ms-1"></i>
            </span>
            <span className="me-3">
              {formatMonto(rating.coach_stars)}
              <i className="fa fa-star ms-1"></i>
            </span>
            {renderPublic()}
          </div>
          <div className="col-6 text-end">
            <span className="small">
              {moment(rating.createdAt).format("DD MMM YYYY HH:mm")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingCard;
