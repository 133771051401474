import { Link } from "@reach/router";
import React, { useState, useEffect } from "react";
import { S3_ENDPOINT, formatMonto } from "../../utils";

const VideoCard = ({ video, handleComments }) => {
  const [classAverage, setClassAverage] = useState(null);
  const [coachAverage, setCoachAverage] = useState(null);

  useEffect(() => {
    getClassStarsAverage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClassStarsAverage = () => {
    if (video.video_ratings.length > 0) {
      let classTotal = 0;
      let coachTotal = 0;
      video.video_ratings.forEach(({ class_stars, coach_stars }) => {
        classTotal += parseInt(class_stars);
        coachTotal += parseInt(coach_stars);
      });
      setClassAverage(classTotal / video.video_ratings.length);
      setCoachAverage(coachTotal / video.video_ratings.length);
    }
  };

  const renderTitle = () => {
    if (String(video.name).length > 40) {
      return String(video.name).substring(0, 40) + "...";
    }
    return video.name;
  };

  const renderDescription = () => {
    if (String(video.description).length > 60) {
      return video.description.substring(0, 60) + "...";
    }
    return video.description;
  };

  const renderInstructorName = () => {
    if (video.instructor && video.instructor !== null) {
      return (
        <p className="bold">
          {video.instructor.name} {video.instructor.last_name}
        </p>
      );
    }
  };

  const renderRating = () => {
    let components = [];
    if (classAverage !== null) {
      components.push(
        <span key="class" className="me-3">
          <div>
            {formatMonto(classAverage)}
            <i className="fa text-warning fa-star ms-1"></i>
          </div>
          <p style={{ fontSize: '12px'}} className="mb-0">Clase</p>
        </span>
      );
    }
    if (coachAverage !== null) {
      components.push(
        <span key="coach" className="me-3">
          <div>
            {formatMonto(coachAverage)}
            <i className="fa text-warning fa-certificate ms-1"></i>
          </div>
          <p style={{ fontSize: '12px'}} className="mb-0">Coach</p>
        </span>
      );
    }
    components.push(
      <span key="playbacks">
        {video.playbacks.length}
        <i className="fa fa-eye ms-1" />
      </span>
    );
    return <div className="mb-2 d-flex justify-content-between">{components}</div>;
  };

  const renderComments = () => {
    if (Array.isArray(video.posts) && video.posts.length > 0) {
      return (
        <button
          key="comments"
          onClick={() => handleComments(video.video_id)}
          className="btn w-100 btn-sm btn-outline-dark"
        >
          <i className="fa fa-comment ms-1"></i>{" "}
          {formatMonto(video.posts.length)}
        </button>
      );
    }
  };

  return (
    <div className="col-12 col-md-6 col-lg-4 col-xl-3 position-relative">
      <div className="card mb-3 shadow-sm no-scale px-0 py-0 pb-3">
        <img
          className="video-thumbnail"
          src={
            video.thumbnail !== null
              ? `${S3_ENDPOINT}/${video.file.name}.${video.file.type}`
              : `${S3_ENDPOINT}/thebodymethod-logo.jpeg`
          }
          alt="video thumbnail"
        />
        <div className="container-fluid px-3 pt-3">
          <h3 className="h5 card-video-title mb-0">{renderTitle()}</h3>
          <p className="mb-1">{renderDescription()}</p>
          {renderInstructorName()}
        </div>
        <div className="container-fluid px-3">
          {renderRating()}
          <div className="row">
            <div className="col-12 col-md-6 text-end">
              <Link
                className="btn btn-sm w-100 btn-outline-primary"
                to={`./${video.video_id}`}
              >
                <i className="fa fa-edit me-1"></i> Editar
              </Link>
            </div>
            <div className="col-12 col-md-6">{renderComments()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
