
import {
  SET_NOTE,
  CREATE_NOTE,
  NOTES_RECEIVED,
  SET_PROPERTY_NOTE,
} from "../types/notes";

const schema = {
  content: ''
}

const NotesReducer = (state, { type, payload }) => {
  switch (type) {
    case NOTES_RECEIVED:
      return { ...state, notes: payload };
    case SET_NOTE:
      return { ...state, note: payload };
    case CREATE_NOTE:
      const { user_id, customer_id } = payload;
      const newNote = {
        ...schema,
        user_id,
        customer_id
      }
      
      return { ...state, note: newNote };
    case SET_PROPERTY_NOTE: {
      const { key, value } = payload;
      const note = { ...state.note };
      note[key] = value;
      return { ...state, note };
    }
    default:
      return { ...state};
  }
};

export default NotesReducer;
