import React from "react";
import CartRow from "./CartRow";

const CartTable = ({ cart_items }) => {
  const renderCarts = () => {
    if (Array.isArray(cart_items)) {
      if (cart_items.length === 0) {
        return <p className="small px-2">No hay carritos todavía</p>;
      }
      return (
        <table className="table border">
          <thead>
            <tr className="bg-light bold border">
              <th>#ID</th>
              <th>Paquete</th>
              <th>Cliente</th>
              <th>Creado</th>
              <th>Ultimo</th>
            </tr>
          </thead>
          <tbody>
            {cart_items.map((cart) => (
              <CartRow key={cart.cart_id} cart={cart} />
            ))}
          </tbody>
        </table>
      );
    }
  };
  return <div className="table-responsive">{renderCarts()}</div>;
};

export default CartTable;
