import { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import AdjuntosService from "../../services/AdjuntosService";
import { S3_ENDPOINT } from "../../utils";

const useCrudPopups = () => {
  const {
    setAppConfigProperty,
    updateAppConfig,
  } = useContext(AppConfigContext);

  const updatePopupFile = async (appconfigKey, file) => {
    if (file) {
      const formData = AdjuntosService.getFormData(file);
      const fileData = (await AdjuntosService.postAdjunto(formData)).data.file;
      const fileName = `${fileData?.name}.${fileData.type}`;
      const updatedFileSrc = `${S3_ENDPOINT}/${fileName}`;

      setAppConfigProperty(appconfigKey, updatedFileSrc);
      await updateAppConfig({ 
        [appconfigKey]: updatedFileSrc 
      });
    }
  };

  return {
    updatePopupFile
  }
};

export default useCrudPopups;
