import React, { useState, useEffect, useContext } from "react";
import firebase from "firebase/app";
import ElementFormPreview from "../../components/editor/EditorFormPreview";
import NewElement from "../../components/editor/NewElement";
import { PagesContext } from "../../context/PagesContext";
import { ModalContext } from "../../context/ModalContext";
import { SITE_URL } from "../../utils";
import PanelTitle from "../../components/global/PanelTitle";

const AdminPageForm = ({ page_id }) => {
  const [dbRef, setDbRef] = useState(null);
  const [elements, setElements] = useState(null);
  const [newElement, setNewElement] = useState(null);

  const { page, getSinglePage } = useContext(PagesContext);

  const { success } = useContext(ModalContext);

  useEffect(() => {
    getSinglePage(page_id);
  }, []);

  useEffect(() => {
    if (page !== null) {
      setDbRef(firebase.database().ref(page.slug));
    }
  }, [page]);

  useEffect(() => {
    if (dbRef !== null) {
      dbRef.on("value", (snap) => {
        let tree = snap.val();
        let currentElements = [];
        if (tree && tree !== null) {
          let objects = Object.keys(tree).map((key) => ({
            component: key,
            props: tree[key],
          }));
          objects.sort((a, b) =>
            parseInt(a.props.orden) > parseInt(b.props.orden) ? 1 : -1
          );
          currentElements = objects;
        }
        setElements(currentElements);
      });
    }
  }, [dbRef]);

  const createElement = () => {
    setNewElement({});
  };

  const move = (direction, name) => {
    const current = [...elements];
    const index = current.findIndex((elem) => elem.component === name);
    if (index !== -1) {
      if (direction === "up") {
        if (index - 1 >= 0) {
          let prevElement = { ...current[index - 1] };
          current[index - 1] = {
            ...current[index],
            props: { ...current[index].props, orden: parseInt(index) },
          };
          current[index] = {
            ...prevElement,
            props: { ...prevElement.props, orden: parseInt(index + 1) },
          };
          dbRef
            .child(current[index].component)
            .update({ ...current[index].props }, (error) => {
              if (error && error !== null)
                return alert(`Error al guardar ${current[index].component}`);
              success(`Cambios de ${current[index].component} guardados.`);
            });
          dbRef
            .child(current[index - 1].component)
            .update({ ...current[index - 1].props }, (error) => {
              if (error && error !== null)
                return alert(
                  `Error al guardar ${current[index - 1].component}`
                );
              success(`Cambios de ${current[index - 1].component} guardados.`);
            });
        }
      } else {
        if (index + 1 < current.length) {
          let nextElement = { ...current[index + 1] };
          current[index + 1] = {
            ...current[index],
            props: {
              ...current[index],
              orden: parseInt(index + 2),
            },
          };
          current[index] = {
            ...nextElement,
            props: { ...nextElement.props, orden: parseInt(index + 1) },
          };
          dbRef
            .child(current[index].component)
            .update({ ...current[index].props }, (error) => {
              if (error && error !== null)
                return alert(`Error al guardar ${current[index].component}`);
              success(`Cambios de ${current[index].component} guardados.`);
            });
          dbRef
            .child(current[index + 1].component)
            .update({ ...current[index + 1].props }, (error) => {
              if (error && error !== null)
                return alert(
                  `Error al guardar ${current[index + 1].component}`
                );
              success(`Cambios de ${current[index + 1].component} guardados.`);
            });
        }
      }
    }
    setElements(current);
  };

  const renderContent = () => {
    if (Array.isArray(elements)) {
      if (elements.length == 0) {
        return <p>No has agregado ningún elemento.</p>;
      }
      return elements.map((elem, index) => (
        <ElementFormPreview
          key={elem.component}
          object={elem}
          dbref={dbRef}
          name={elem.component}
          props={elem.props}
          move={move}
        />
      ));
    }
    return (
      <div className="h-100 text-center py-5">
        <div className="spinner-border"></div>
      </div>
    );
  };

  const renderTitle = () => {
    if (page && page !== null) {
      return page.title;
    }
    return "Nueva Página";
  };

  const renderNewElement = () => {
    if (newElement !== null) {
      return (
        <NewElement
          dbref={dbRef}
          orden={elements.length}
          currentElements={elements}
          disable={() => setNewElement(null)}
        />
      );
    }
  };

  const renderPreviewButton = () => {
    if (page && page !== null) {
      if (page.page_id !== "nueva") {
        return (
          <a
            href={`${SITE_URL}/pages/${page.slug}`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-outline-primary bold shadow-sm mx-2"
          >
            <i className="fa fa-eye"></i> Ver
          </a>
        );
      }
    }
  };

  return (
    <div className="container-fluid">
      <div className="container-fluid">
        <PanelTitle title="Editar Página" hideButton />
      </div>
      <div className="row mx-0 align-items-center">
        <div className="col-6">
          <h4>{renderTitle()}</h4>
        </div>
        <div className="col-6 text-end">
          {renderPreviewButton()}
          <button
            className="btn btn-success bold shadow-sm"
            onClick={createElement}
          >
            + Agregar
          </button>
        </div>
      </div>
      {renderNewElement()}
      {renderContent()}
    </div>
  );
};

export default AdminPageForm;
