import React, { useContext, useEffect } from "react";
import { MusclesContext } from "../../context/MusclesContext";
import { getValue } from "../../utils";
import { VideosContext } from "../../context/VideosContext";

const MuscleForm = ({ handleCallback }) => {
  const { tags, getTags } = useContext(VideosContext);
  const { muscle, saveMuscle, setPropertyMuscle } = useContext(MusclesContext);

  useEffect(() => {
    getTags();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveMuscle(muscle, handleCallback);
  };

  const renderOptions = () => {
    if (Array.isArray(tags)) {
      return tags.map((tag) => (
        <option key={tag.tag_id} value={tag.tag_id}>
          {tag.name}
        </option>
      ));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Nombre</label>
      <input
        type="text"
        className="form-control mb-3"
        value={getValue(muscle, "name")}
        onChange={(e) => setPropertyMuscle("name", e.target.value)}
      />
      <label>Categoría</label>
      <select
        className="form-control mb-3"
        value={getValue(muscle, "tag_id")}
        onChange={(e) => setPropertyMuscle("tag_id", e.target.value)}
      >
        <option value="">Elige una opción</option>
        {renderOptions()}
      </select>
      <div className="row">
        <div className="col-6">
          <button type="button" className="btn text-muted w-100">
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn btn-primary w-100">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default MuscleForm;
