import { useContext } from "react"
import { AuthContext } from "../../context/AuthContext"


const useUserRoles = () => {
  const { user } = useContext(AuthContext);

  const isBranchAdmin = () => {
    if(user.branch_admin) {
      return true;
    } else {
      return false;
    }
  }

  const isSuperAdmin = () => {
    if(user.role === 'super_admin') {
      return true;
    } else {
      return false;
    }
  }

  const isAdmin = (specific) => {
    let roles = ['super_admin', 'admin'];
    if(specific) roles = ['admin'];

    if(roles.includes(user.role)) {
      return true;
    } else {
      return false;
    }
  }

  const isManager = (specific) => {
    let roles = ['super_admin', 'admin', 'manager'];
    if(specific) roles = ['manager'];

    if(roles.includes(user.role)) {
      return true;
    } else {
      return false;
    }
  }

  const isCoach = (specific) => {
    let roles = ['super_admin', 'admin', 'manager', 'coach'];
    if(specific) roles = ['manager'];

    if(roles.includes(user.role)) {
      return true;
    } else {
      return false;
    }
  }


  return {
    isBranchAdmin,
    isSuperAdmin,
    isAdmin,
    isManager,
    isCoach
  }

}

export default useUserRoles;