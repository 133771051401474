import React, { useContext } from "react";
import moment from "moment";
import { CustomerContext } from "../../context/CustomerContext";
import { ReservationsContext } from "../../context/ReservationsContext";

const ReservacionRow = ({ reservation, deleteCallback }) => {
  const { customer } = useContext(CustomerContext);
  const { eliminarReservacion } = useContext(ReservationsContext);
  
  const getPaymentMethod = (reservacion) => {
    if (reservacion.is_cash) return <i className="fas fa-money-bill"></i>;
  };

  const getStatusReservacion = (reservacion) => {
    if (reservacion.deletedAt !== null) {
      return <span className="badge badge-pill bg-danger">Cancelada</span>;
    }
    if (reservacion.is_cash) {
      if (!reservacion.is_paid) {
        return (
          <span className="badge badge-pill bg-danger">
            Pago en Efectivo Incompleto
          </span>
        );
      }
    }
    if (
      !reservacion.attend &&
      moment().isAfter(moment(reservacion.single_class.class_date))
    ) {
      return (
        <span className="badge badge-pill text-dark bg-warning">
          No Asistió
        </span>
      );
    }
    if (reservacion.attend) {
      return <span className="badge badge-pill bg-success">Asistió</span>;
    }
  };

  const renderInstructors = () => {
    if (
      reservation.single_class.class_instructors &&
      reservation.single_class.class_instructors !== null
    ) {
      return reservation.single_class.class_instructors
        .filter(({ instructor }) => instructor ? true : false)
        .map(({ instructor }) => instructor.name + " " + instructor.last_name)
        .join(", ");
    }
  };

  const renderClassType = () => {
    if (reservation.single_class.class_type !== null) {
      return reservation.single_class.class_type.name;
    }
  };

  const handleDeleteReservacion = async () => {
    await eliminarReservacion(reservation.class_reservation_id, deleteCallback);
  }

  return (
    <tr className="">
      <td className=" ">{renderClassType()}</td>
      <td className=" ">{renderInstructors()}</td>
      <td className=" ">
        {moment(reservation.single_class.class_date)
          .utc()
          .format("DD MMM HH:mm")}
      </td>
      <td className=" ">
        {moment(reservation.createdAt).format("DD MMM YYYY HH:mm:ss")}
      </td>
      <td className=" ">
        {getPaymentMethod(reservation)} {getStatusReservacion(reservation)}
      </td>
      <td className="  text-center">
        {reservation.spot}
      </td>
      <td className="  ">
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://wa.me/52${customer?.phone}`}
          className={
            customer?.phone !== null ? "text-success" : "text-secondary"
          }
        >
          <i className="fab fa-whatsapp me-2"></i>
          {customer?.phone}
        </a>
      </td>
      <td className="  text-center">
        <button 
          type="button"
          className="btn btn-sm btn-outline-danger "
          onClick={handleDeleteReservacion}
        >
            <i className="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  );
};

export default ReservacionRow;
