import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import useBranch from "../hooks/useBranch";
import Panel from "./Panel";

const BranchPanel = () => {
  const { branch, branches, selectBranch } = useBranch();
  const { user } = useContext(AuthContext);

  const renderContent = () => {
    if (branch !== null) {
      return <Panel />;
    }
    if (Array.isArray(branches)) {
      if (user.role === "super_admin") {
        return (
          <div className="container-fluid">
            <div className="row vh-100 align-items-center">
              <div className="container text-center">
                <img
                  alt="logo"
                  src="/images/logo.png"
                  style={{ maxWidth: 150 }}
                  className="logo-login d-block m-auto"
                />
                <h1>Elegir Franquicia</h1>
                <div className="row">
                  {branches.map((branch) => (
                    <div key={branch.branch_id} className="col">
                      <div className="card border p-3 shadow mb-3">
                        <h2>{branch.name}</h2>
                        <button
                          onClick={() => selectBranch(branch.branch_id)}
                          className="btn bold text-white text-uppercase"
                          style={{ backgroundColor: branch.primary_color }}
                        >
                          Acceder
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  };

  return <div>{renderContent()}</div>;
};

export default BranchPanel;
