
import {
  SET_STREAK,
  CREATE_STREAK,
  STREAKS_RECEIVED,
  SET_PROPERTY_STREAK,
} from "../types/streaks";

const schema = {

}

const streaksReducer = (state, { type, payload }) => {
  switch (type) {
    case STREAKS_RECEIVED:
      return { ...state, streaks: payload };
    case SET_STREAK:
      return { ...state, streak: payload };
    case CREATE_STREAK:
      return { ...state, streak: schema };
    case SET_PROPERTY_STREAK: {
      const { key, value } = payload;
      const streak = { ...state.streak };
      streak[key] = value;
      return { ...state, streak };
    }
    default:
      return { ...state};
  }
};

export default streaksReducer;
