/**
 * Analiticas
 */
export const VIDEOS_RECIBIDOS = "VIDEOS_RECIBIDOS";
export const INGRESOS_RECBIDOS = "INGRESOS_RECBIDOS";
export const COACHES_RECIBIDAS = "COACHES_RECIBIDAS";
export const DISCOUNTS_RECEIVED = "DISCOUNTS_RECEIVED";
export const PAQUETES_RECIBIDOS = "PAQUETES_RECIBIDOS";
export const ADDRESSES_RECEIVED = 'ADDRESSES_RECEIVED';
export const FAVORITOS_RECIBIDOS = "FAVORITOS_RECIBIDOS";
export const INSCRITOS_RECIBIDOS = "INSCRITOS_RECIBIDOS";
export const TRIAL_CLASS_RECEIVED = "TRIAL_CLASS_RECEIVED";
export const FREE_TRIALS_RECIBIDAS = "FREE_TRIALS_RECBIDAS";
export const LEADERBORARD_RECEIVED = "LEADERBOARD_RECEIVED";
export const RESERVATIONS_RECIBIDAS = "RESERVATIONS_RECIBIDAS";
export const SIGNUP_REASONS_RECEIVED = "SIGNUP_REASONS_RECEIVED";
