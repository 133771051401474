
import React, { createContext, useReducer, useContext } from 'react';
import MusclesService from '../services/MusclesService';
import MusclesReducer from '../reducers/MusclesReducer';
import {
  MUSCLES_RECEIVED,
  SET_MUSCLE,
  CREATE_MUSCLE,
  SET_PROPERTY_MUSCLE,
} from "../types/muscles";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  muscles: null,
  muscle: null,
};

export const MusclesContext = createContext(initialState);

export const MusclesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MusclesReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getMuscles = () => {
    MusclesService.getMuscles()
      .then((response) => {
        const { muscles } = response.data;
        dispatch({ type: MUSCLES_RECEIVED, payload: muscles });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleMuscle = (muscle_id) => {
    MusclesService.getSingleMuscle(muscle_id)
      .then((response) => {
        const { muscle } = response.data;
        dispatch({ type: SET_MUSCLE, payload: muscle });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setMuscle = (muscle) => {
    dispatch({ type: SET_MUSCLE, payload: muscle });
  };

  const createMuscle = () => {
    dispatch({ type: CREATE_MUSCLE });
  };

  const setPropertyMuscle = (key, value) => {
    dispatch({ type: SET_PROPERTY_MUSCLE, payload: { key, value } });
  };

  const saveMuscle = (muscle, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = MusclesService.putMuscle;
      if(isNaN(parseInt(muscle.muscle_id))) {
        service = MusclesService.postMuscle;
      }
      service(muscle).then(() => {
        success("Muscle saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteMuscle = (muscle_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    MusclesService.deleteMuscle(muscle_id).then(() => {
      success("Muscle deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <MusclesContext.Provider
    value={{
      ...state,
      setMuscle,
      getMuscles,
      saveMuscle,
      deleteMuscle,
      createMuscle,
      getSingleMuscle,
      setPropertyMuscle,

    }}
  >
    {children}
  </MusclesContext.Provider>
);
};
