import React from "react";

const PostReply = ({ post, handleThread }) => {
  const renderReplyTo = () => {
    if (post.replyTo && post.replyTo !== null) {
      return (
        <div
          onClick={() => handleThread(post.thread_id)}
          className="reply px-4 bg-white br-15 text-dark py-2 my-2"
        >
          <span className="d-block bold small">
            Respondiendo a{" "}
            {post.replyTo.anonymous ? "Anónimo" : post.replyTo.customer.name}
          </span>
          {post.replyTo.content}
        </div>
      );
    }
  };
  return <div>{renderReplyTo()}</div>;
};
export default PostReply;
