import { getArgs } from "../utils";
import api from "./api";

const route = "/users";

const UserService = {
  getAllUsers: (filters) => api.get(`${route}/admin/all?${getArgs(filters)}`),
  getbranchScopes: (filters) => api.get(`${route}/branch/all?${getArgs(filters)}`),
  getCurrentUser: () => api.get(route),
  postUser: (data) => api.post(route, data),
  putUser: (data) => api.put(route, data),
  deleteUser: (user_id) => api.delete(`${route}/${user_id}`),
};

export default UserService;
