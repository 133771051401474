import React from "react";

const PanelTitle = ({ title, onClick, hideButton, buttonComponent }) => {

  const renderButton = () => {
    if(buttonComponent && !hideButton) {
      return buttonComponent;

    } else if(!hideButton) {
      return (
        <div className="col col-md-6 px-0 text-end">
          <button className="btn btn-primary" onClick={onClick}>
            + Agregar
          </button>
        </div>
      )
    } else {
      return (
        <div className="col col-md-6 px-0 text-end">
        </div>
      )
    }
  }


  return (
    <div className="row mx-0 align-items-center mb-3 pb-3 border-bottom" style={{height: 57}}>
      <div className="col col-md-6 px-0">
        <h1 className="h2 bold mb-0">{title}</h1>
      </div>
      {renderButton()}
    </div>
  );
};

export default PanelTitle;
