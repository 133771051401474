import React from "react";
import { getValue } from "../../utils";
import useUserRoles from "../../hooks/auth/useUserRoles";

const PropRow = ({ prop }) => {
  const { isSuperAdmin } = useUserRoles();

  const renderSuperAdminCols = () => {
    if(isSuperAdmin()) {
      return (
        <div className="col">
          <button className="btn btn-sm btn-outline-primary me-2">
            <i className="fa fa-edit" />
          </button>
          <button className="btn btn-sm btn-outline-danger">
            <i className="fa fa-trash" />
          </button>
        </div>
      )
    }
  }

  return (
    <div className="row px-3 py-2">
      <div className="col">{getValue(prop, "name")}</div>
      {renderSuperAdminCols()}
    </div>
  );
};

export default PropRow;
