import React, { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import CustomersTable from "../../components/customers/CustomersTable";
import Pagination from "../../components/global/Pagination";
import { Link } from "@reach/router";

const AdminClientes = () => {
  const [page, setPage] = useState(1);
  const [field, setField] = useState("");
  const [query, setQuery] = useState("");
  const [sort, setSort] = useState("name");
  const [direction, setDirection] = useState("ASC");

  const { customers, getAllCustomers } = useContext(CustomerContext);

  useEffect(() => {
    fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (query.length > 0) {
      const timeout = setTimeout(fetchCustomers, 500);
      return () => clearTimeout(timeout);
    } else {
      fetchCustomers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, page, field, sort, direction]);

  const fetchCustomers = () => {
    getAllCustomers({ query, page, field, sort, direction });
  };

  return (
    <div className="container-fluid">
      <div className="row mx-0 align-items-center">
        <div className="col-12 col-md-4 ps-0">
          <h1>Clientes</h1>
        </div>
        <div className="col-12 col-md-8 px-0">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <input
                type="text"
                value={query}
                placeholder="Buscar..."
                className="form-control"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <div className="col-12 col-md-6 text-right">
              <div className="row">
                <div className="col-6">
                  <select
                    className="form-control"
                    value={field}
                    onChange={(e) => setField(e.target.value)}
                  >
                    <option value="">Todo</option>
                    <option value="name">Nombre</option>
                    <option value="last_name">Apellidos</option>
                    <option value="email">Correo</option>
                    <option value="phone">Teléfono</option>
                  </select>
                </div>
                <div className="col-6">
                  <Link
                    to="/myadmin/customer/nuevo/edit"
                    className="btn btn-primary w-100"
                  >
                    <i className="fa fa-plus me-2" /> Agregar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card no-scale p-3">
        {Array.isArray(customers) ? (
          <CustomersTable
            customers={customers}
            setSort={setSort}
            direction={direction}
            selected={sort}
            setDirection={setDirection}
          />
        ) : (
          <div className="spinner-border" />
        )}
        <Pagination setPage={setPage} page={page} />

      </div>
    </div>
  );
};

export default AdminClientes;
