import React, { useContext } from "react";
import { LocationsContext } from "../../context/LocationsContext";
import { AuthContext } from "../../context/AuthContext";
import SelectBranch from "../branches/SelectBranch";
import { ModalContext } from "../../context/ModalContext";

const LocationForm = ({ modifier, postLocation }) => {
  const { location } = useContext(LocationsContext);
  const { user } = useContext(AuthContext);
  const { clearModal } = useContext(ModalContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    postLocation(location, clearModal);
  };

  const renderSelectBranch = () => {
    if(user.branch_admin) {
      return (
        <div className="row mx-auto mb-3">
          <div className="col-12 px-0">
            <SelectBranch
              selectedBranch={location.branch_id}
              setSelectedBranch={(selectedBranch) => {
                modifier('branch_id', selectedBranch);
              }}
            />
          </div>
        </div>
      )
    }
  }

  const renderForm = () => {
    if (location && location !== null) {
      const { name, address } = location;
      return (
        <form onSubmit={handleSubmit}>
          <label>Nombre</label>
          <input
            type="text"
            className="form-control mb-3"
            value={name}
            onChange={(e) => modifier("name", e.target.value)}
          />
          
          <label>Dirección</label>
          <input
            type="text"
            className="form-control mb-3"
            value={address}
            onChange={(e) => modifier("address", e.target.value)}
          />
          
          <div className="row mt-2">
            <div className="col-6">
              <button
                type="button"
                onClick={clearModal}
                className="btn text-muted w-100"
              >
                Cancelar
              </button>
            </div>
            <div className="col-6">
              <button type="submit" className="btn btn-primary w-100">
                Guardar
              </button>
            </div>
          </div>
        </form>
      );
    }
  };

  return <div className="container-fluid px-0">{renderForm()}</div>;
};

export default LocationForm;
