import React, { useState, useContext, useEffect } from "react";
import { ModalContext } from "../../context/ModalContext";
import { PostsContext } from "../../context/PostsContext";
import { AuthContext } from "../../context/AuthContext";
import ConfirmDeletePost from "./ConfirmDeletePost";
import { S3_ENDPOINT } from "../../utils";
import UserImage from "../users/UserImage";
import UserIcon from "../users/UserIcon";
import PostReply from "./PostReply";
import PostVideo from "./PostVideo";
import PostMeta from "./PostMeta";

const PostCard = ({
  post,
  hideVideo,
  setReplyTo,
  handleVideo,
  hideReplies,
  handleThread,
  handleCallback,
}) => {
  const [fileComponents, setFileComponents] = useState([]);

  const { user } = useContext(AuthContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { deletePost, savePostLike, deletePostLike } = useContext(PostsContext);

  const [showActions, setShowActions] = useState(false);

  const isMyPost = post.customer_id === user.customer_id;

  useEffect(() => {
    handleFiles();
  }, []);

  const handleDelete = () => {
    modalComponent(
      "Eliminar Comentario",
      <ConfirmDeletePost
        handleCallback={handleCallback}
        handleCancel={clearModal}
        deletePost={deletePost}
        post={post}
      />
    );
  };

  const handleViewFile = (file) => {
    modalComponent(
      "",
      <img
        src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
        className="mw-100 w-100 d-block m-auto"
      />,
      { centered: true }
    );
  };

  const toggleLike = () => {
    let index = post.post_likes.findIndex(
      (like) => parseInt(like.customer_id) === parseInt(user.customer_id)
    );
    if (index === -1) savePostLike(post.post_id, user.customer_id);
    else deletePostLike(post.post_id, user.customer_id);
  };

  const getPostColor = () => {
    if (isMyPost) {
      return "bg-secondary-light";
    }
    if (post.customer && post.customer !== null) {
      if (post.customer.user && post.customer.user !== null) {
        return ["super_admin","admin"].includes(post.customer.user.role)
          ? "bg-primary-light text-dark"
          : "bg-primary text-white";
      }
    }
    return "bg-light";
  };

  const renderActions = () => {
    if (showActions) {
      let components = [];
      if (post.customer_id !== user.customer_id) {
        components.push(
          <button
            onClick={() => setReplyTo(post)}
            className="btn btn-sm text-start w-100"
          >
            <i className="fa fa-reply me-2"></i> Responder
          </button>
        );
      }
      components.push(
        <button onClick={handleDelete} className="btn btn-sm text-start w-100">
          <i className="fa fa-trash-alt me-2"></i> Eliminar
        </button>
      );
      return (
        <div className="border bg-white px-2 shadow-sm post-actions text-start">
          {components}
        </div>
      );
    }
  };

  const renderLikes = () => {
    if (Array.isArray(post.post_likes)) {
      return (
        <button onClick={toggleLike} className="btn px-0 py-0 mx-2">
          <i
            className={`fa fa-heart ${
              post.post_likes.length > 0 ? "text-danger me-2" : "text-muted"
            }`}
          ></i>
          {post.post_likes.length > 0 ? post.post_likes.length : ""}
        </button>
      );
    }
  };

  const handleFiles = () => {
    if (Array.isArray(post.files) && post.files.length > 0) {
      setFileComponents(
        <div className="mb-2">
          {post.files.map((file) => (
            <img
              src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
              onClick={() => handleViewFile(file)}
              className="thumbnail me-2"
              key={file.file_id}
            />
          ))}
        </div>
      );
    }
  };

  return (
    <div
      id={`post-${post.post_id}`}
      className={`card border-0 position-relative no-scale px-2 px-md-4 py-1 mb-2 ${getPostColor()}`}
    >
      <div className="row align-items-center">
        <div className="col-2 col-md-1 px-0 text-center hide-mobile">
          {post.anonymous ? (
            <UserIcon />
          ) : (
            <UserImage customer={post.customer} />
          )}
        </div>
        <div className="col-12 col-md-11 ps-0">
          <div className={`px-4 py-2`}>
            <PostMeta post={post} isMyPost={isMyPost} />
            {!hideVideo && <PostVideo post={post} handleVideo={handleVideo} />}
            {!hideReplies && (
              <PostReply post={post} handleThread={handleThread} />
            )}
            <div className="row">
              <div className="col-8">
                {fileComponents}
                <p className="medium">{post.content}</p>
              </div>
              <div className="col-4 text-end pe-0">
                {renderLikes()}
                <button
                  onClick={() => setShowActions(!showActions)}
                  className="btn text-muted p-2"
                >
                  <i className="fa fa-ellipsis-v" />
                </button>
                {renderActions()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostCard;
