import React, { useContext, useState, useEffect } from "react";
import { AnaliticasContext } from "../../context/AnaliticasContext";
import RatingComments from "../../components/ratings/RatingComments";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import VideosTable from "../../components/videos/VideosTable";
import { VideosContext } from "../../context/VideosContext";
import OffCanvas from "../../components/global/OffCanvas";
import Chart from "react-apexcharts";

const AnaliticasVideos = () => {
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const {
    class_types,
    instructors,
    favorites,
    playbacks,
    getVideos,
    hours,
    last,
  } = useContext(AnaliticasContext);
  const { video_ratings, getVideoRatings, clearVideoRatings } =
    useContext(VideosContext);

  useEffect(() => {
    if (Array.isArray(video_ratings)) {
      setShowOffCanvas(true);
    } else {
      setShowOffCanvas(false);
    }
  }, [video_ratings]);

  useEffect(() => {
    if (!showOffCanvas) {
      clearVideoRatings();
    }
  }, [showOffCanvas]);

  const fetchData = (start_date, end_date) => {
    getVideos(start_date, end_date);
  };

  const fetchComments = (id_value, key_id) => {
    getVideoRatings({ [key_id]: id_value });
  };

  const renderComments = () => {
    if (Array.isArray(video_ratings)) {
      return <RatingComments ratings={video_ratings} />;
    }
  };

  const renderChart = () => {
    if (hours && hours !== null) {
      let data = [...hours];
      data = hours.map(({ playbacks, hour }) => ({
        playbacks,
        hour: hour - 6 < 0 ? hour - 6 + 24 : hour - 6,
      }));
      data.sort((a, b) => (a.hour < b.hour ? -1 : 1));
      return (
        <Chart
          type="bar"
          height="300"
          options={{
            colors: ["#ffc0e1"],
            xaxis: {
              categories: data.map(({ hour }) => hour),
            },
          }}
          series={[
            {
              name: "Reproducciones por Hora",
              data: data.map(({ playbacks }) => playbacks),
            },
          ]}
        />
      );
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitleDate callback={fetchData} />
      <div className="row">
        <div className="container-fluid">
          <div className="card shadow-sm p-3 no-scale me-3 mb-3">
            <h4 className="border-bottom mb-3 pb-3">Reproducciones</h4>
            <div className="row bold">
              <div className="container-fluid">{renderChart()}</div>
            </div>
          </div>
          <div className="card shadow-sm p-3 no-scale me-3">
            <h4 className="border-bottom mb-3 pb-3">
              Top 10 Más Agregados a favoritos
            </h4>
            <VideosTable
              handleComments={fetchComments}
              videos={favorites}
              key_id="video_id"
            />
          </div>
          <div className="card shadow-sm p-3 no-scale me-3 mt-4">
            <h4 className="border-bottom mb-3 pb-3">Top 10 Más Reproducidos</h4>
            <VideosTable
              handleComments={fetchComments}
              videos={playbacks}
              key_id="video_id"
            />
          </div>
          <div className="card shadow-sm p-3 no-scale me-3 mt-4">
            <h4 className="border-bottom mb-3 pb-3">Tipos de Clases</h4>
            <VideosTable
              handleComments={fetchComments}
              key_id="class_type_id"
              videos={class_types}
              hideCoach
              hideDate
            />
          </div>
          <div className="card shadow-sm p-3 no-scale me-3 mt-4">
            <h4 className="border-bottom mb-3 pb-3">Instructores</h4>
            <VideosTable
              handleComments={fetchComments}
              key_id="instructor_id"
              videos={instructors}
              hideCoach
              hideDate
            />
          </div>
          <div className="card shadow-sm p-3 no-scale me-3 mt-4">
            <h4 className="border-bottom mb-3 pb-3">Últimas 10 Clases</h4>
            <VideosTable
              handleComments={fetchComments}
              key_id="video_id"
              videos={last}
            />
          </div>
        </div>
      </div>
      <OffCanvas
        showOffCanvas={showOffCanvas}
        setShowOffCanvas={setShowOffCanvas}
      >
        {renderComments()}
      </OffCanvas>
    </div>
  );
};

export default AnaliticasVideos;
