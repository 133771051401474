import React, { useEffect, useState, useContext } from "react";
import { CoachesContext } from "../../context/CoachesContext";
import { S3_ENDPOINT, getValue } from "../../utils";
import BirthdateInput from "../common/BirthdateInput";
import SelectBranch from "../branches/SelectBranch";
import SelectCoachCustomer from "./SelectCoachCustomer";
import SelectDiscountCoach from "./SelectDiscountCoach";
import ReactSwitch from "react-switch";
import { AuthContext } from "../../context/AuthContext";

const CoachForm = ({ idCoach, handleCancel }) => {
  const { coach, getCoach, createCoach, postCoach, setPropiedadCoach } =
    useContext(CoachesContext);

  const { user } = useContext(AuthContext);

  const [file, setFile] = useState(null);
  const [src, setSrc] = useState(null);

  useEffect(() => {
    fetchCoach(idCoach);
  }, [idCoach]);

  useEffect(() => {
    if (file !== null) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSrc(null);
    }
  }, [file]);

  const fetchCoach = (idCoach) => {
    if (isNaN(idCoach)) {
      createCoach();
    } else {
      getCoach(idCoach);
    }
  };

  const renderShowHome = () => {
    return(
      <div className={`row mb-3`}>
        <div className="w-50 d-flex justify-content-between align-items-center">
          <label className="">Mostrar en Home</label>
          <ReactSwitch
            checked={coach.show_home}
            onChange={(checked) => {
              setPropiedadCoach('show_home', checked);
            }}
          />
        </div>

        <div className="w-50 ">
          <label className="">Orden</label>
          <input
            className="form-control"
            type="number"
            min={0}
            value={coach.order}
            onChange={(event) => {
              const value = event.target.value;
              setPropiedadCoach('order', value);
            }}
          />
        </div>
       
      </div>
    )
  }

  const handleCoachDiscount = (discount) => {
    setPropiedadCoach("discount_id", discount.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postCoach({ ...coach, file }, handleCancel);
  };

  const renderFile = () => {
    if (coach && coach !== null) {
      if (coach.file) {
        return (
          <img
            src={`${S3_ENDPOINT}/${coach.file.name}.${coach.file.type}`}
            className="mw-100 w-100 d-block m-auto"
            alt="coach"
          />
        );
      }
      if (src !== null) {
        return <img src={src} className="mw-100 w-100 d-block m-auto" alt="coach_photo"/>;
      }
    }
  };

  const renderForm = () => {
    if (coach && coach !== null) {
      return (
        <form onSubmit={handleSubmit}>
          <div className="row mb-3">
            <div className="col-6">
              <label>Nombre</label>
              <input
                type="text"
                className="form-control"
                value={getValue(coach, "name")}
                onChange={(e) => setPropiedadCoach("name", e.target.value)}
              />
            </div>
            <div className="col-6">
              <label>Apellidos</label>
              <input
                type="text"
                className="form-control"
                value={getValue(coach, "last_name")}
                onChange={(e) => setPropiedadCoach("last_name", e.target.value)}
              />
            </div>
          </div>

          <label>Fotografía</label>
          <div className="row mb-4">
            <div className="col-4">{renderFile()}</div>
            <div className="col-8">
              <input
                type="file"
                className="form-control"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </div>
          </div>
          <label>Fecha de Nacimiento</label>
          <BirthdateInput
            value={getValue(coach, "birthdate")}
            modifier={(value) => setPropiedadCoach("birthdate", value)}
          />
          <label>Apodo</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(coach, "nick_name")}
            onChange={(e) => setPropiedadCoach("nick_name", e.target.value)}
          />

          <div className="row">
            <div className="col-6">
              <label>Instagram</label>
              <input
                type="text"
                className="form-control mb-3"
                value={getValue(coach, "instagram")}
                onChange={(e) => setPropiedadCoach("instagram", e.target.value)}
              />
            </div>

            <div className="col-6 ">
              <label>TikTok</label>
              <input
                type="text"
                className="form-control mb-3"
                value={getValue(coach, "tiktok")}
                onChange={(e) => setPropiedadCoach("tiktok", e.target.value)}
              />
            </div>
          </div>
          
          <label>Quote</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(coach, "quote")}
            onChange={(e) => setPropiedadCoach("quote", e.target.value)}
          />
          <label>Bio</label>
          <textarea
            rows="5"
            className="form-control mb-3"
            value={getValue(coach, "short_bio")}
            onChange={(e) => setPropiedadCoach("short_bio", e.target.value)}
          />
          {renderShowHome()}
          <SelectDiscountCoach coach={coach} modifier={handleCoachDiscount} />
          {!isNaN(idCoach) && (
            <SelectCoachCustomer coach={coach} modifier={setPropiedadCoach} />
          )}
          <div className="row mt-2">
            <div className="col-6">
              <button
                type="button"
                onClick={handleCancel}
                className="btn text-muted w-100"
              >
                Cancelar
              </button>
            </div>
            <div className="col-6">
              <button type="submit" className="btn btn-primary w-100">
                Guardar
              </button>
            </div>
          </div>
        </form>
      );
    }
  };

  return <div className="container-fluid px-0">{renderForm()}</div>;
};

export default CoachForm;
