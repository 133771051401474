
import api from './api';

const route = '/props';

export default {
  getProps: () => api.get(route),
  getSingleProp: (prop_id) => api.get(`${route}/${prop_id}`),
  postProp: (prop) => api.post(route, { ...prop}),
  putProp: (prop) => api.put(route, { ...prop}),
  deleteProp: (Prop) => api.delete(`${route}/${Prop}`),
};
