import api from "./api";

const route = "/testimonials";

const TestimoniosService = {
  getTestimonioById: (testimonial_id) => api.get(`${route}/${testimonial_id}`),
  getAllTestimonios: () => api.get(`${route}/all`),
  postTestament: (data) => api.post(`${route}`, data),
  updateTestament: (testimonio_id, data) => api.put(`${route}/${testimonio_id}`, data),
  deleteTestament: (testimonial_id) => api.delete(`${route}/${testimonial_id}`),
};

export default TestimoniosService;
