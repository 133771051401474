import {
  ADD_BRANCH,
  CREATE_DESCUENTO,
  DESCUENTOS_RECIBIDOS,
  REMOVE_BRANCH,
  SET_DESCUENTO,
  SET_PROPIEDAD_DESCUENTO,
} from "../types";

const schema = {
  discount_id: "nuevo",
  title: "",
  code: "",
  description: "",
  amount: "",
  branches: [],
  is_percent: true,
  start_date: null,
  expiration_date: null,
  limit_per_customer: null,
  available: true,
};

const DiscountsReducer = (state, { type, payload }) => {
  switch (type) {
    case DESCUENTOS_RECIBIDOS:
      return { ...state, descuentos: payload };
    case SET_DESCUENTO:
      return { ...state, descuento: payload };

    case ADD_BRANCH: {      
      const { branches } = state.descuento;
      if (Array.isArray(branches)) {
        branches.push({
          branch: payload,
          branch_id: payload.branch_id,
        });
      }

      console.log(branches);
      
      return { ...state, descuento: { ...state.descuento, branches } };
    }

    case REMOVE_BRANCH: {
      const { branches } = state.descuento;
      if (Array.isArray(branches)) {
        const index = branches.findIndex(
          (branch) => String(branch.branch_id) === String(payload)
        );
        if (index !== -1) {
          branches.splice(index, 1);
        }
      }
      return { ...state, descuento: { ...state.descuento, branches } };
    }
     
    case SET_PROPIEDAD_DESCUENTO:
      const { key, value } = payload;
      const descuento = { ...state.descuento };
      descuento[key] = value;
      return { ...state, descuento };
    case CREATE_DESCUENTO:
      return { ...state, descuento: schema };
    default:
      return { ...state };
  }
};

export default DiscountsReducer;
