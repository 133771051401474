import React, { useContext } from "react";
import PanelTitleDate from "../../components/global/PanelTitleDate";
import TrialClassRow from "../../components/analytics/TrialClassRow";
import { AnaliticasContext } from "../../context/AnaliticasContext";

const AnaliticasClasesPrueba = () => {
  const { trials, getTrialClassAnalytics } = useContext(AnaliticasContext);

  const handleCallback = (start_date, end_date) => {
    getTrialClassAnalytics({ start_date, end_date });
  };

  const renderTrialClasses = () => {
    if (Array.isArray(trials)) {
      if (trials.length === 0) {
        return <p className="small m-3">No hay clases de prueba registradas en las fechas elegidas.</p>;
      }
      return trials.map((trial) => (
        <TrialClassRow key={trial.customer_id} trial={trial} />
      ));
    }
  };
  return (
    <div className="container-fluid">
      <PanelTitleDate title="Clases de Prueba" callback={handleCallback} />
      <div className="card shadow no-scale">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="bg-light bold p-2">
                <th>#ID</th>
                <th>Nombre</th>
                <th>Email</th>
                <th>Cuenta Creada</th>
                <th>Fecha Compra</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>{renderTrialClasses()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AnaliticasClasesPrueba;
