import { getArgs } from "../utils";
import api from "./api";

const route = "/customer";

const CustomerService = {
  getCustomer: (customer_id, filters) => api.get(`${route}/admin/${customer_id}?${getArgs(filters)}`),
  getAllCustomers: (filters) =>
    api.get(`${route}/admin/all?${getArgs(filters)}`),
  getCustomersByQuery: (query) => api.get(`${route}/query?query=${query}`),
  getOnlineActiveCustomers: () => api.get(`${route}/admin/online`),
  getStudioActiveCustomers: () => api.get(`${route}/admin/studio`),
  downloadOnlineActiveCustomers: () =>
    api.get(`${route}/admin/online?download=true`, { responseType: "blob" }),
  downloadStudioActiveCustomers: () =>
    api.get(`${route}/admin/studio?download=true`, { responseType: "blob" }),
  postCustomer: (customer) => api.post(route, { ...customer }),
  putCustomer: (customer) => api.put(route, { ...customer }),
  putCurrentCustomer: (customer) => api.put(route, { ...customer }),
  postOnlineAccess: (data) => api.post(`${route}/onlineAccess`, data),
  putOnlineAccess: (data) => api.put(`${route}/onlineAccess`, data),
  extenderAcceso: (data) =>
    api.post(`${route}/giveAccess`, data),
  giveClasses: (data) =>
    api.post(`${route}/giveClasses`, data),
  revokeAccess: (purchase_id) =>
    api.put(`${route}/revokeAccess`, { purchase_id }),
  removeClasses: (customer_id, amount) =>
    api.post(`${route}/removeClasses`, { customer_id, amount }),
  recoverPassword: (email) => api.post(`${route}/recover`, { email }),
  deleteCustomer: (customer_id) => api.delete(`${route}/${customer_id}`),
  getPasswordResetLink: (email) =>
    api.post(`${route}/resetPasswordLink`, { email }),
};

export default CustomerService;
