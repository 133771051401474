import React from "react";

const ConfirmDeletePost = ({
  post,
  deletePost,
  handleCancel,
  handleCallback,
}) => {
  return (
    <div>
      <p>¿Estás segura que deseas eliminar este comentario?</p>
      <div className="row">
        <div className="col-6">
          <button onClick={handleCancel} className="btn w-100 text-muted">
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button
            onClick={() => deletePost(post.post_id, handleCallback)}
            className="btn w-100 btn-danger"
          >
            Eliminar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeletePost;
