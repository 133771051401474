import React, { createContext, useReducer, useContext } from "react";
import CartsService from "../services/CartsService";
import CartsReducer from "../reducers/CartsReducer";
import { CARTS_RECEIVED } from "../types/carts";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import { ModalContext } from "./ModalContext";

const initialState = {
  cart_items: null,
  cart: null,
};

export const CartsContext = createContext(initialState);

export const CartsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CartsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getCarts = (filters) => {
    CartsService.getCarts(filters)
      .then((response) => {
        const { cart_items } = response.data;
        dispatch({ type: CARTS_RECEIVED, payload: cart_items });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const deleteCart = (cart_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    CartsService.deleteCart(cart_id)
      .then(() => {
        success("Cart deleted.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <CartsContext.Provider
      value={{
        ...state,
        getCarts,
        deleteCart,
      }}
    >
      {children}
    </CartsContext.Provider>
  );
};
