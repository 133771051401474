import moment from "moment";
import React from "react";

const PostMeta = ({ post, isMyPost }) => {
  const renderStaff = () => {
    if (post.customer.user && post.customer.user !== null) {
      return <i className="fa fa-user-shield me-2" />;
    }
  };
  return (
    <div className="row mb-1">
      <div className="col-6 col-md-8">
        <span className="small bold">
          {renderStaff()}
          {post.customer.name}
        </span>
      </div>
      <div className="col-6 col-md-4 text-end">
        <span className={`small ${isMyPost ? "" : "text-muted"}`}>
          {moment(post.createdAt).format("DD MMM YYYY HH:mm")}
        </span>
      </div>
    </div>
  );
};
export default PostMeta;
