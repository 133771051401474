
import api from './api';

const route = '/muscles';

export default {
  getMuscles: () => api.get(route),
  getSingleMuscle: (muscle_id) => api.get(`${route}/${muscle_id}`),
  postMuscle: (muscle) => api.post(route, { ...muscle}),
  putMuscle: (muscle) => api.put(route, { ...muscle}),
  deleteMuscle: (Muscle) => api.delete(`${route}/${Muscle}`),
};
