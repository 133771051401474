
import {
  SET_PROP,
  CREATE_PROP,
  PROPS_RECEIVED,
  SET_PROPERTY_PROP,
} from "../types/props";

const schema = {

}

const propsReducer = (state, { type, payload }) => {
  switch (type) {
    case PROPS_RECEIVED:
      return { ...state, props: payload };
    case SET_PROP:
      return { ...state, prop: payload };
    case CREATE_PROP:
      return { ...state, prop: schema };
    case SET_PROPERTY_PROP: {
      const { key, value } = payload;
      const prop = { ...state.prop };
      prop[key] = value;
      return { ...state, prop };
    }
    default:
      return { ...state};
  }
};

export default propsReducer;
