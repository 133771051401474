import React, { useEffect, useState } from "react";
import { getValue } from "../../utils";

const VideoMuscleFormRow = ({ muscle, video, modifier }) => {
  const [prevMuscles, setPrevMuscles] = useState(null);
  const [videoMuscle, setVideoMuscle] = useState(null);

  useEffect(() => {
    if (prevMuscles !== video.video_muscles) {
      setPrevMuscles(video.video_muscles);
      findVideoMuscle();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video]);

  const findVideoMuscle = () => {
    if (Array.isArray(video.video_muscles)) {
      const index = video.video_muscles.findIndex(
        ({ muscle_id }) => muscle_id === muscle.muscle_id
      );
      if (index !== -1) setVideoMuscle(video.video_muscles[index]);
      else setVideoMuscle(null);
    }
  };

  const renderForm = () => {
    if (videoMuscle !== null) {
      return (
        <div className="col-5">
          <div className="btn-group">
            <button
              onClick={() => {
                modifier(muscle.muscle_id, "primary", true);
                modifier(muscle.muscle_id, "secondary", false);
              }}
              type="button"
              className={`btn btn-sm btn-${
                getValue(videoMuscle, "primary", "boolean")
                  ? "primary"
                  : "outline-primary"
              }`}
            >
              Primario
            </button>
            <button
              onClick={() => {
                modifier(muscle.muscle_id, "primary", false);
                modifier(muscle.muscle_id, "secondary", true);
              }}
              type="button"
              className={`btn btn-sm btn-${
                getValue(videoMuscle, "secondary", "boolean")
                  ? "primary"
                  : "outline-primary"
              }`}
            >
              Secundario
            </button>
          </div>
        </div>
      );
    }
  };

  const checked = videoMuscle !== null;

  return (
    <div className="row py-2 border-bottom align-items-center">
      <div className="col-1">
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) =>
            modifier(muscle.muscle_id, "checked", !checked)
          }
        />
      </div>
      <div className="col-6">{getValue(muscle, "name")}</div>
      {renderForm()}
    </div>
  );
};

export default VideoMuscleFormRow;
