import React from "react";
import HeaderRow from "../global/HeaderRow";
import PropRow from "./PropRow";
import useUserRoles from "../../hooks/auth/useUserRoles";

const PropTable = ({ props, handleEdit, handleDelete }) => {
  const { isSuperAdmin } = useUserRoles();


  const renderProps = () => {
    if (Array.isArray(props)) {
      if (props.length === 0) {
        return <p className="m-2 small">No hay props registrados</p>;
      }
      return props.map((prop) => (
        <PropRow
          prop={prop}
          key={prop.prop_id}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      ));
    }
  };

  const getHeaders = () => {
    const headers = ["Nombre"];
    if(isSuperAdmin()) headers.push("Acciones");

    return headers;
  }

  return (
    <div className="container-fluid">
      <HeaderRow headers={getHeaders()} />
      {renderProps()}
    </div>
  );
};

export default PropTable;
