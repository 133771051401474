import React from "react";

const AddresesTable = ({ group, addresses }) => {
  const renderAddresses = () => {
    if (Array.isArray(addresses)) {
      if (addresses.length === 0) {
        return <p>No hay registros disponibles.</p>;
      }
      return (
        <div className="table-reponsive">
          <table className="table">
            <thead>
              <tr className="bold bg-light border">
                {group === "city" && <td>Ciudad</td>}
                <td>Estado</td>
                <td>País</td>
                <td>Clientes</td>
              </tr>
            </thead>
            <tbody>
              {addresses.map((address) => (
                <tr>
                  {group === "city" && <td>{address.city}</td>}
                  <td>{address.state}</td>
                  <td>{address.country}</td>
                  <td>{address.customers}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  };

  return <div>{renderAddresses()}</div>;
};

export default AddresesTable;