import { Link } from "@reach/router";
import moment from "moment";
import React, { useContext } from "react";
import { displayDayTime } from "../../utils";
import AvailableClassForm from "./AvailableClassForm";
import { AvailableClassesContext } from "../../context/AvailableClassesContext";
import { ModalContext } from "../../context/ModalContext";

const AvailableClassRow = ({ 
  available_class,
  handleCallback, 
  selectable,
  selected, 
  onToggle 
}) => {
  const { setAvailableClass, deleteAvailableClass } = useContext(AvailableClassesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleCancel = () => {
    setAvailableClass(null);
    clearModal();
  };

  const handleEdit = () => {
    setAvailableClass(available_class);
    modalComponent(
      "Editar Clase",
      <AvailableClassForm
        handleCancel={handleCancel}
        handleCallback={handleCallback}
      />
    );
  };

  const handleDelete = () => {
    modalComponent(
      "Eliminar Clase",
      <div>
        <p>
          ¿Estás segura que deseas eliminar el crédito #
          {available_class.available_class_id} del usuario?
        </p>
        <p>Esta acción NO puede deshacerse.</p>
        <div className="row">
        <div className="col">
          <button
            onClick={clearModal}
            className="btn w-100 text-muted"
          >
            Cancelar
          </button>
        </div>
        <div className="col">
          <button onClick={() => deleteAvailableClass(available_class.available_class_id, handleCallback)} className="btn w-100 btn-danger">
            Eliminar
          </button>
        </div>
      </div>
      </div>
    );
  };

  const renderAvailableClassId = () => {
    if(selectable) {
      return (
        <td> 
          <input
            className={`
              form-check-input me-2 
              ${selected ? 'bg-primary' : ''}
            `}
            onChange={() => onToggle(available_class.available_class_id)}
            checked={selected}
            type="checkbox"
          />
          {available_class.available_class_id}
        </td>
      )
    } else {
      return (
        <td>{available_class.available_class_id}</td>
      )
    }
  }

  return (
    <tr>
      {renderAvailableClassId()}
      <td>{moment(available_class.createdAt).utc().format("DD MMM YYYY")}</td>
      <td>
        {available_class.start_date !== null
          ? moment(available_class.start_date).utc().format("DD MMM YYYY HH:mm")
          : ""}
      </td>
      <td>
        {moment(available_class.expiration_date)
          .utc()
          .format("DD MMM YYYY HH:mm")}
      </td>
      <td>{available_class.purchase_id}</td>
      <td>
        {available_class.class_reservation &&
          available_class.class_reservation !== null && (
            <Link
              to={`/myadmin/asistentes/${available_class.class_reservation.single_class_id}`}
            >
              <i className="fa fa-eye" />{" "}
              {displayDayTime(
                available_class.class_reservation.single_class.class_date
              )}
            </Link>
          )}
      </td>
      <td>
        {available_class.class_reservation_id === null && [
          <button
            key="edit"
            onClick={handleEdit}
            className="btn btn-sm btn-outline-dark"
          >
            <i className="fa fa-edit" />
          </button>,
          <button key="delete" onClick={handleDelete} className="btn ms-2 btn-sm btn-outline-danger">
            <i className="fa fa-trash" />
          </button>,
        ]}
      </td>
    </tr>
  );
};

export default AvailableClassRow;
