import React, { useContext, useEffect, useState } from "react";
import { DiscountsContext } from "../../context/DiscountsContext";
import { AuthContext } from "../../context/AuthContext";
import Switch from "react-switch";
import { getValue, hideModal } from "../../utils";
import SelectMultipleBranches from "../branches/SelectMultipleBranches";

const DescuentoForm = ({ discount_id }) => {
  const [type, setType] = useState(1);
  const {
    descuento,
    getDescuento,
    createDescuento,
    postDescuento,
    setPropiedadDescuento,
    addBranch,
    removeBranch
  } = useContext(DiscountsContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (isNaN(discount_id)) {
      createDescuento();
    } else {
      getDescuento(discount_id);
    }
  }, [discount_id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    postDescuento({ ...descuento, isPercent: type === 1 });
  };

  const renderSelectMultipleBranches = () => {
    if(user.branch_admin) {
      return (
        <SelectMultipleBranches
          selected={descuento.branches}
          addBranch={addBranch}
          removeBranch={removeBranch}
        />
      )
    }
  }

  const renderForm = () => {
    if (descuento && descuento !== null) {
      return (
        <form onSubmit={handleSubmit}>
          <div className="row mb-3">
            <div className="col-6">Disponible</div>
            <div className="col-6 text-end">
              <Switch
                checked={getValue(descuento, "available", "boolean")}
                onChange={(checked) =>
                  setPropiedadDescuento("available", checked)
                }
              />
            </div>
          </div>
          <label>Código</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(descuento, "code")}
            onChange={(e) => setPropiedadDescuento("code", e.target.value)}
          />

          {renderSelectMultipleBranches()}
          
          <label>Descripción</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(descuento, "description")}
            onChange={(e) =>
              setPropiedadDescuento("description", e.target.value)
            }
          />
          <label>Tipo de Descuento</label>
          <select
            className="form-control mb-3"
            value={getValue(descuento, "type")}
            onChange={(e) => {
              setType(e.target.value);
              setPropiedadDescuento("is_percent", e.target.value === 1);
            }}
          >
            <option value="1">Porcentaje (%)</option>
            <option value="0">Cantidad Fija ($)</option>
          </select>
          <label>Cantidad</label>
          <input
            type="number"
            className="form-control mb-3"
            value={getValue(descuento, "amount")}
            onChange={(e) => setPropiedadDescuento("amount", e.target.value)}
          />
          <label>Fecha Inicio</label>
          <input
            type="date"
            className="form-control mb-3"
            value={getValue(descuento, "start_date", "date")}
            onChange={(e) =>
              setPropiedadDescuento("start_date", e.target.value)
            }
          />
          <label>Fecha Fin</label>
          <input
            type="date"
            className="form-control mb-3"
            value={getValue(descuento, "expiration_date", "date")}
            onChange={(e) =>
              setPropiedadDescuento("expiration_date", e.target.value)
            }
          />
          <label>Cantidad por Cliente</label>
          <input
            type="number"
            className="form-control mb-3"
            value={getValue(descuento, "limit_per_customer")}
            onChange={(e) =>
              setPropiedadDescuento("limit_per_customer", e.target.value)
            }
          />
          <div className="row mb-3">
            <div className="col-6">Sólo para primer pago</div>
            <div className="col-6 text-end">
              <Switch
                checked={getValue(descuento, "first_invoice_only", "boolean")}
                onChange={(checked) =>
                  setPropiedadDescuento("first_invoice_only", checked)
                }
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-6">Sólo para clientes nuevos</div>
            <div className="col-6 text-end">
              <Switch
                checked={getValue(descuento, "first_purchase_only", "boolean")}
                onChange={(checked) =>
                  setPropiedadDescuento("first_purchase_only", checked)
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <button type="submit" className="btn btn-dark w-100 mt-2">
                Guardar
              </button>
            </div>
            <div className="col-6 text-right">
              <button
                className="btn btn-link text-secondary mt-2"
                onClick={(e) => {
                  e.preventDefault();
                  hideModal();
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </form>
      );
    }
  };

  return <div className="container-fluid px-0">{renderForm()}</div>;
};

export default DescuentoForm;
