
import React, { createContext, useReducer, useContext } from 'react';
import FrequentQuestionsService from '../services/FrequentQuestionsService';
import FrequentQuestionsReducer from '../reducers/FrequentQuestionsReducer';
import {
  FREQUENTQUESTIONS_RECEIVED,
  SET_FREQUENTQUESTION,
  CREATE_FREQUENTQUESTION,
  SET_PROPERTY_FREQUENTQUESTION,
} from "../types/FrequentQuestions";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  allQuestions: null,
  question: {
    question: '',
    response: ''
  },
};


export const FrequentQuestionsContext = createContext(initialState);

export const FrequentQuestionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(FrequentQuestionsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const clearQuestion = () => {
    dispatch({ type: SET_FREQUENTQUESTION, payload: {
      question: '',
      response: ''
    }});
  }

  const getFrequentQuestions = () => {
    FrequentQuestionsService.getFrequentQuestions()
      .then((response) => {
        const { questions } = response.data;
        dispatch({ type: FREQUENTQUESTIONS_RECEIVED, payload: questions });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleFrequentQuestion = (frequentQuestionId) => {
    FrequentQuestionsService.getSingleFrequentQuestion(frequentQuestionId)
      .then((response) => {
        const { question } = response.data;
        dispatch({ type: SET_FREQUENTQUESTION, payload: question });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setFrequentQuestion = (payload) => {
    dispatch({ type: SET_FREQUENTQUESTION, payload });
  };

  const createFrequentQuestion = () => {
    dispatch({ type: CREATE_FREQUENTQUESTION });
  };

  const setPropertyFrequentQuestion = (key, value) => {
    dispatch({ type: SET_PROPERTY_FREQUENTQUESTION, payload: { key, value } });
  };

  const saveFrequentQuestion = (question, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = FrequentQuestionsService.putFrequentQuestion;
      if(isNaN(parseInt(question.frequent_question_id))) {
        service = FrequentQuestionsService.postFrequentQuestion;
      }
      service(question).then(() => {
        success("Preguta Frecuente guardada.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteFrequentQuestion = (frequent_question_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    
    FrequentQuestionsService.deleteFrequentQuestion(frequent_question_id).then(() => {
      success("Pregunta Frecuente eliminada.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <FrequentQuestionsContext.Provider
    value={{
      ...state,
      setFrequentQuestion,
      getFrequentQuestions,
      saveFrequentQuestion,
      deleteFrequentQuestion,
      createFrequentQuestion,
      getSingleFrequentQuestion,
      setPropertyFrequentQuestion,
      clearQuestion

    }}
  >
    {children}
  </FrequentQuestionsContext.Provider>
);
};
