import React from "react";

const UserIcon = () => {
  return (
    <div className="btn btn-round shadow-sm btn-light">
      <i className="fa fa-user"></i>
    </div>
  );
};

export default UserIcon;
