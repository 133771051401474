
import api from './api';

const route = '/frequent_questions';

const FrequentQuestionsService = {
  getFrequentQuestions: () => api.get(`${route}/admin/all`),
  getSingleFrequentQuestion: (FrequentQuestion_id) => api.get(`${route}/${FrequentQuestion_id}`),
  postFrequentQuestion: (FrequentQuestion) => api.post(route, { ...FrequentQuestion}),
  putFrequentQuestion: (FrequentQuestion) => api.put(route, { ...FrequentQuestion}),
  deleteFrequentQuestion: (FrequentQuestion) => api.delete(`${route}/${FrequentQuestion}`),
};

export default FrequentQuestionsService;
