import React, { useContext, useEffect, useState } from "react";
import { FrequentQuestionsContext } from "../../context/FrequentQuestionsContext";
import PanelTitle from "../../components/global/PanelTitle";
import EditorJS from "react-editor-js";
import { EDITOR_JS_TOOLS, parseBlock, htmlToBlocks } from "../../components/editor/EditorTools";
import { navigate } from "@reach/router";


const AdminSingleFrequentQuestion = ({ question_id }) => {
  const [instance, setInstance] = useState(null);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const editMode = question_id === 'new' ? false : true;
  const viewTitle = editMode ? 'Editar Pregunta Frecuente' : 'Crear Pregunta Frecuente';

  const {
    question,
    setPropertyFrequentQuestion,
    saveFrequentQuestion,
    getSingleFrequentQuestion,
    clearQuestion
  } = useContext(FrequentQuestionsContext);


  useEffect(() => {
    if(editMode) getSingleFrequentQuestion(question_id);

    return () => clearQuestion();
  }, []);


  useEffect(() => {
    if(editorLoaded && question && firstLoad) {
      handleFirstLoad();
    }
  }, [editorLoaded, question]);

  useEffect(() => {
    if(question && editMode && editorLoaded) {
      const currentBlocks = htmlToBlocks(question.response);
      instance.render({ blocks: currentBlocks})
    }
  }, [question]);

  const handleFirstLoad = () => {
    setFirstLoad(false);

    if(editMode) {
      const currentBlocks = htmlToBlocks(question.response);

      instance.render({ blocks: currentBlocks})
    } else {
      const defaultBlock = {
        id: "default",
        type: "header",
        data: {
          text: "Comienza a escribir aqui!",
          level: 2
        }
      }

      instance.render({ blocks: [defaultBlock]})
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = await instance.save();
    let content = '';

    data.blocks.forEach((block) => {
      content += parseBlock(block);
    });

    question.response = content;
    const callback = () => navigate('/myadmin/frequent_questions');
    saveFrequentQuestion(question, callback);
  }


  return (
    <div className="contiainer-fluid px-3">
      <PanelTitle title={viewTitle} hideButton />

      <div className="card p-3 shadow-sm no-scale" style={{cursor: 'unset'}}>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <label className="bold">Pregunta</label>
              <input
                type="text"
                className="form-control"
                value={question.question}
                onChange={(event) => setPropertyFrequentQuestion('question', event.target.value)}
              />
            </div>

            <div className="col-12 mt-3">
              <label className="bold">Respuesta</label>
              <EditorJS
                onReady={() => setEditorLoaded(true)}
                instanceRef={(current) => setInstance(current)}
                tools={EDITOR_JS_TOOLS}
              />
            </div>

            <div className="col-12 mt-3 text-end">
              <button
                type="button"
                onClick={() => navigate('/myadmin/frequent_questions')}
                className="btn btn-link text-secondary"
              >
                Cancelar
              </button>

              <button 
                type="submit" 
                className="btn btn-primary "
              >
                Guardar
              </button>
            </div>
          </div>
        </form>
      </div>
      
    </div>
  );
};

export default AdminSingleFrequentQuestion;
