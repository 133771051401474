import React, { useContext, useState } from "react";
import moment from "moment";
import { ModalContext } from "../../context/ModalContext";
import { AvailableClassesContext } from "../../context/AvailableClassesContext";
import { CustomerContext } from "../../context/CustomerContext";
const AddClassesForm = ({ customer_id, addClasses }) => {
  const [amount, setAmount] = useState(0);
  const [expiration, setExpiration] = useState(moment().add(30, 'days').format('yyyy-MM-DD'));
  const [start, setStart] = useState(moment().format('yyyy-MM-DD'));

  const { clearModal } = useContext(ModalContext);
  const { getAvailableClassesByCustomerId } = useContext(AvailableClassesContext);
  const { getCustomer } = useContext(CustomerContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    addClasses(
      {
        customer_id, 
        amount, 
        expiration_date: expiration, 
        start_date: start, 
      },
      () => {
        getAvailableClassesByCustomerId(customer_id);
        getCustomer(customer_id);
      }
    );
  };

  return (
    <div className="container-fluid ">
      <form onSubmit={handleSubmit}>
        <label>Ingresa la cantidad a agregar</label>
        <input
          type="number"
          value={amount}
          className="form-control mb-3"
          onChange={(e) => setAmount(e.target.value)}
        />

        <label>Fecha de Inicio</label>
        <input
          type="date"
          value={start}
          className="form-control mb-3"
          onChange={(e) => setStart(e.target.value)}
        />

        <label>Fecha de Expiración</label>
        <input
          type="date"
          value={expiration}
          className="form-control mb-3"
          onChange={(e) => setExpiration(e.target.value)}
        />


        <div className="row mt-3">
          <div className="col-6">
            <button 
              type="button" 
              className="btn btn-link text-secondary w-100"
              onClick={clearModal}
            >
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button type="submit" className="btn btn-primary w-100">
              Agregar Clases
            </button>
          </div>

        </div>

   
      </form>
    </div>
  );
};

export default AddClassesForm;
