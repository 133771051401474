
import {
  SET_FREQUENTQUESTION,
  CREATE_FREQUENTQUESTION,
  FREQUENTQUESTIONS_RECEIVED,
  SET_PROPERTY_FREQUENTQUESTION,
} from "../types/FrequentQuestions";

const schema = {
  frequent_question_id: 'new',
  question: '',
  response: ''
}

const FrequentQuestionsReducer = (state, { type, payload }) => {
  switch (type) {
    case FREQUENTQUESTIONS_RECEIVED:
      return { ...state, allQuestions: payload };
    case SET_FREQUENTQUESTION:
      return { ...state, question: payload };
    case CREATE_FREQUENTQUESTION:
      return { ...state, question: schema };
    case SET_PROPERTY_FREQUENTQUESTION: {
      const { key, value } = payload;
      const question = { ...state.question };
      question[key] = value;
      return { ...state, question };
    }
    default:
      return { ...state};
  }
};

export default FrequentQuestionsReducer;
