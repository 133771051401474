
import React, { createContext, useReducer, useContext } from 'react';
import BranchesService from '../services/BranchesService';
import BranchesReducer from '../reducers/BranchesReducer';
import {
  BRANCHES_RECEIVED,
  SET_BRANCH,
  CREATE_BRANCH,
  SET_PROPERTY_BRANCH,
  SET_BRANCH_FORM,
  SET_EDIT_BRANCH_MODE,
} from "../types/branches";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  branches: null,
  branch: null,
  editBranchMode: false,
  branchForm: {
    name: '',
    description: ''
  }
};

export const BranchesContext = createContext(initialState);

export const BranchesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(BranchesReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getBranches = () => {
    BranchesService.getBranches()
      .then((response) => {
        const { branches } = response.data;
        dispatch({ type: BRANCHES_RECEIVED, payload: branches });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleBranch = (branch_id) => {
    BranchesService.getSingleBranch(branch_id)
      .then((response) => {
        const { branch } = response.data;
        dispatch({ type: SET_BRANCH, payload: branch });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setBranch = (branch) => {
    dispatch({ type: SET_BRANCH, payload: branch });
  };

  const setEditBranchMode = (payload) => {
    dispatch({ type: SET_EDIT_BRANCH_MODE, payload });
  }

  const setBranchForm = (branchForm) => {
    dispatch({ type: SET_BRANCH_FORM, payload: branchForm });
  };

  const createBranch = () => {
    dispatch({ type: CREATE_BRANCH });
  };

  const setPropertyBranch = (key, value) => {
    dispatch({ type: SET_PROPERTY_BRANCH, payload: { key, value } });
  };

  const saveBranch = (branch, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = BranchesService.putBranch;
      if(isNaN(parseInt(branch.branch_id))) {
        service = BranchesService.postBranch;
      }
      service(branch).then(() => {
        success("Branch saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteBranch = (branch_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    BranchesService.deleteBranch(branch_id).then(() => {
      success("Branch deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <BranchesContext.Provider
    value={{
      ...state,
      setBranch,
      setBranchForm,
      setEditBranchMode,
      getBranches,
      saveBranch,
      deleteBranch,
      createBranch,
      getSingleBranch,
      setPropertyBranch,

    }}
  >
    {children}
  </BranchesContext.Provider>
);
};
