import { Router } from "@reach/router";
import React from "react";
import AdminFreeTrials from "./admin/AdminFreeTrials";
import AnaliticasVideos from "./analiticas/AnaliticasVideos";
import AnaliticasIngresos from "./analiticas/AnaliticasIngresos";
import AnaliticasPaquetes from "./analiticas/AnaliticasPaquetes";
import AnaliticasInscritos from "./analiticas/AnaliticasInscritos";
import AnaliticasAddresses from "./analiticas/AnaliticasAddresses";
import AnaliticasDescuentos from "./analiticas/AnaliticasDescuentos";
import AnaliticasClasesPrueba from "./analiticas/AnaliticasClasesPrueba";
import AnaliticasInstructores from "./analiticas/AnaliticasInstructores";
import AnaliticasLeaderboards from "./analiticas/AnaliticasLeaderboards";
import AnaliticasSignupReasons from "./analiticas/AnaliticasSignupReasons";
import AnaliticasReservaciones from "./analiticas/AnaliticasReservaciones";
import AnaliticasDescuentoCoach from "./analiticas/AnaliticasDescuentoCoach";
import AnaliticasOnlineLeaderboard from "./analiticas/AnaliticasOnlineLeaderboard";


const Analitica = () => {
  return (
    <Router>
      <AdminFreeTrials path="/free" />
      <AnaliticasVideos path="/videos" />
      <AnaliticasPaquetes path="/paquetes" />
      <AnaliticasIngresos path="/ingresos" />
      <AnaliticasClasesPrueba path="/trials" />
      <AnaliticasAddresses path="/addresses" />
      <AnaliticasDescuentos path="/discounts" />
      <AnaliticasLeaderboards path="/leaderboard" />
      <AnaliticasInstructores path="/instructores" />
      <AnaliticasInscritos path="/inscritos" default />
      <AnaliticasReservaciones path="/reservaciones" />
      <AnaliticasSignupReasons path="/signup_reasons" />
      <AnaliticasDescuentoCoach path="/coach/descuentos" />
      <AnaliticasOnlineLeaderboard path="/leaderboard/online" />
    </Router>
  );
};

export default Analitica;
