import React from "react";
import VideoRow from "./VideoRow";

const VideosTable = ({
  key_id,
  videos,
  hideCoach,
  hideDate,
  handleComments,
}) => {
  const renderVideos = () => {
    if (Array.isArray(videos)) {
      return videos.map((video, index) => (
        <VideoRow
          video={video}
          key_id={key_id}
          key={video[key_id]}
          hideDate={hideDate}
          hideCoach={hideCoach}
          handleComments={handleComments}
        />
      ));
    }
  };

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr className="bg-light border bold">
            <td>Nombre</td>
            {!hideCoach && <td>Coach</td>}
            {!hideDate && <td>Fecha</td>}
            <td>Totales</td>
            <td>Únicas</td>
            <td>Calificación</td>
          </tr>
        </thead>
        <tbody>{renderVideos()}</tbody>
      </table>
    </div>
  );
};

export default VideosTable;
