import { Link } from "@reach/router";
import moment from "moment";
import React from "react";

const TrialClassRow = ({ trial }) => {
  return (
    <tr>
      <td>{trial.customer_id}</td>
      <td>
        <Link to={`/myadmin/customer/${trial.customer_id}`}>
          <i className="fa fa-user me-1"></i>{trial.name}</Link>
      </td>
      <td className="small">{trial.email}</td>
      <td>{moment(trial.signup_date).utc().format("DD MMM YYYY")}</td>
      <td>{moment(trial.purchase_date).utc().format("DD MMM YYYY")}</td>
      <td>
        {trial.purchases > 1 ? (
          <span className="badge badge-pill bg-success">Convertido</span>
        ) : (
          <span className="badge bg-warning badge-pill">No Convertido</span>
        )}
      </td>
    </tr>
  );
};
export default TrialClassRow;
