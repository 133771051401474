import React, { useContext, useEffect, useState } from "react";
import { BranchesContext } from "../../context/BranchesContext"

const SelectMultipleBranches = ({
  selected,
  addBranch,
  removeBranch,
}) => {
  const [query, setQuery] = useState("");
  const { branches, getBranches } = useContext(BranchesContext);

  useEffect(() => {
    getBranches();
  }, []);

  const renderBranches = () => {
    if (Array.isArray(branches) && query !== "") {
      let branchesRender = [...branches];
      if (query !== "") {
        branchesRender = branchesRender.filter(
          (branch) =>
            String(branch.name)
              .toLowerCase()
              .startsWith(String(query).toLowerCase())
        );
      }

      if (Array.isArray(selected)) {
        const current = selected.map(
          ({ branch }) => branch.branch_id
        );
        branchesRender = branchesRender.filter(
          (branch) => !current.includes(branch.branch_id)
        );
      }

      return branchesRender
        .slice(0, 3)
        .map((branch) => (
          <li
            key={branch.branch_id}
            className="dropdown-item border"
            onClick={() => addBranch(branch)}
          >
            {branch.name}
          </li>
        ))
        .concat(
          <li
            key="nuevo"
            className="dropdown-item border pointer"
            onClick={() => {
              addBranch({ branch_id: "nuevo", name: query });
              setQuery("");
            }}
          >
            + Crear Branch "{query}"
          </li>
        );
    }
  };

  const renderSelected = () => {
    if (Array.isArray(selected)) {
      return selected.map(({ branch }) => (
        <span
          key={branch.branch_id}
          className="badge badge-pill bg-light border text-dark p-2 me-1"
        >
          {branch.name}
          <i
            className="fa fa-times small ms-1 pointer"
            onClick={() => removeBranch(branch.branch_id)}
          ></i>
        </span>
      ));
    }
  };

  return (
    <div className="mb-3">
      <label>Branch(es)</label>
      <input
        type="text"
        value={query}
        className="form-control"
        placeholder="Buscar por nombre"
        onChange={(e) => setQuery(e.target.value)}
      />
      <div>{renderBranches()}</div>
      <div className="py-2">{renderSelected()}</div>
    </div>
  );
};

export default SelectMultipleBranches;
