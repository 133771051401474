
import React, { createContext, useReducer, useContext } from 'react';
import PropsService from '../services/PropsService';
import PropsReducer from '../reducers/PropsReducer';
import {
  PROPS_RECEIVED,
  SET_PROP,
  CREATE_PROP,
  SET_PROPERTY_PROP,
} from "../types/props";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  props: null,
  prop: null,
};

export const PropsContext = createContext(initialState);

export const PropsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PropsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getProps = () => {
    PropsService.getProps()
      .then((response) => {
        const { props } = response.data;
        dispatch({ type: PROPS_RECEIVED, payload: props });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleProp = (prop_id) => {
    PropsService.getSingleProp(prop_id)
      .then((response) => {
        const { prop } = response.data;
        dispatch({ type: SET_PROP, payload: prop });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setProp = (prop) => {
    dispatch({ type: SET_PROP, payload: prop });
  };

  const createProp = () => {
    dispatch({ type: CREATE_PROP });
  };

  const setPropertyProp = (key, value) => {
    dispatch({ type: SET_PROPERTY_PROP, payload: { key, value } });
  };

  const saveProp = (prop, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = PropsService.putProp;
      if(isNaN(parseInt(prop.prop_id))) {
        service = PropsService.postProp;
      }
      service(prop).then(() => {
        success("Prop saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteProp = (prop_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    PropsService.deleteProp(prop_id).then(() => {
      success("Prop deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <PropsContext.Provider
    value={{
      ...state,
      setProp,
      getProps,
      saveProp,
      deleteProp,
      createProp,
      getSingleProp,
      setPropertyProp,

    }}
  >
    {children}
  </PropsContext.Provider>
);
};
