import React, { useContext } from "react";
import { PropsContext } from "../../context/PropsContext";
import { getValue } from "../../utils";

const PropForm = ({ handleCallback }) => {
  const { prop, saveProp, setPropertyProp } = useContext(PropsContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveProp(prop, handleCallback);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Nombre</label>
      <input
        type="text"
        className="form-control mb-3"
        value={getValue(prop, "name")}
        onChange={(e) => setPropertyProp("name", e.target.value)}
      />
      <div className="row">
        <div className="col-6">
          <button type="button" className="btn text-muted w-100">
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn btn-primary w-100">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default PropForm;
