
import api from './api';

const route = '/threads';

export default {
  getThreads: () => api.get(route),
  getSingleThread: (thread_id) => api.get(`${route}/${thread_id}`),
  postThread: (thread) => api.post(route, { ...thread}),
  putThread: (thread) => api.put(route, { ...thread}),
  deleteThread: (Thread) => api.delete(`${route}/${Thread}`),
};
