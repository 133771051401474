
import React, { createContext, useReducer, useContext } from 'react';
import StreaksService from '../services/StreaksService';
import StreaksReducer from '../reducers/StreaksReducer';
import {
  SET_STREAK,
  STREAKS_RECEIVED,
  SET_PROPERTY_STREAK,
} from "../types/streaks";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  streaks: null,
  streak: null,
};

export const StreaksContext = createContext(initialState);

export const StreaksProvider = ({ children }) => {
  const [state, dispatch] = useReducer(StreaksReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getCustomerStreaks = (customer_id) => {
    StreaksService.getCustomerStreaks(customer_id)
      .then((response) => {
        const { streaks } = response.data;
        dispatch({ type: STREAKS_RECEIVED, payload: streaks });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setStreak = (streak) => {
    dispatch({ type: SET_STREAK, payload: streak });
  };

  const setPropertyStreak = (key, value) => {
    dispatch({ type: SET_PROPERTY_STREAK, payload: { key, value } });
  };

  const saveStreak = (streak, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = StreaksService.putStreak;
      if(isNaN(parseInt(streak.streak_id))) {
        service = StreaksService.postStreak;
      }
      service(streak).then(() => {
        success("Streak saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteStreak = (streak_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    StreaksService.deleteStreak(streak_id).then(() => {
      success("Streak deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

  return (
    <StreaksContext.Provider
    value={{
      ...state,
      setStreak,
      saveStreak,
      deleteStreak,
      setPropertyStreak,
      getCustomerStreaks,
    }}
  >
    {children}
  </StreaksContext.Provider>
);
};
