import React, { createContext, useContext, useReducer } from "react";
import ReservationsReducer from "../reducers/ReservationsReducer";
import ReservationsService from "../services/ReservationsService";
import { ModalContext } from "./ModalContext";
import { hideModal } from "../utils";
import {
  RESERVATIONS_RATINGS_RECEIVED,
  RESERVATIONS_RECEIVED,
  SET_CURRENT_FILTER,
} from "../types/reservations";

const initialState = {
  reservations: null,
  reservation: null,
  currentReservationsFilter: 'all',
  reservationFilters: [
    { name: 'Todas', value: 'all' },
    { name: 'Próximas', value: 'upcoming' },
    { name: 'Pasadas', value: 'outdated' },
    { name: 'Canceladas', value: 'cancelled' },
  ],
  updated: false,
  ratings: null,
};

export const ReservationsContext = createContext(initialState);

export const ReservationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ReservationsReducer, initialState);

  const { success } = useContext(ModalContext);

  const setCurrentFilter = (payload) => {
    dispatch({ type: SET_CURRENT_FILTER, payload });
  }

  const getMyReservations = () => {
    ReservationsService.getMyReservations().then((res) => {
      const { reservations } = res.data;
      dispatch({ type: RESERVATIONS_RECEIVED, payload: reservations });
    });
  };

  const cancelReservacion = (class_reservation_id, callback) => {
    ReservationsService.cancelReservation(class_reservation_id)
      .then(() => {
        hideModal();
        success("¡Reservación cancelada con éxito!");
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            hideModal();
            alert(
              "Lo sentimos, para cancelar tu clase necesitas al menos 6 horas de anticipación."
            );
          }
        }
        if (typeof callback === "function") {
          callback();
        }
      });
  };

  const postReservacion = (clase, callback) => {
    let service;
    if (clase.class_reservation_id) {
      service = ReservationsService.putReservacion(clase);
    } else {
      service = ReservationsService.postReservation(clase);
    }
    service
      .then(() => {
        hideModal();
        success("¡Reservación guardada con éxito!");
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 412) {
            return alert(
              "Lo sentimos, ya no tienes créditos vigentes para clases presenciales."
            );
          } else if (error.response.status === 409) {
            return alert(
              "Ya has reservado esta clase, refresca la página para verlo reflejado."
            );
          }
        }
        if (typeof callback === "function") {
          callback();
        }
      });
  };

  const postAttend = (class_reservation_id, attend, callback) => {
    ReservationsService.postAttend(class_reservation_id, attend).then(() => {
      success(attend ? "Asistencia registrada." : "Asistencia cancelada");
      if (typeof callback === "function") {
        callback();
      }
    });
  };

  const updateGuestName = (class_reservation_id, name) => {
    ReservationsService.updateGuestName(class_reservation_id, name).then(() => {
      success("¡Nombre actualizado!");
    });
  };

  const eliminarReservacion = (class_reservation_id, callback) => {
    ReservationsService.cancelReservation(class_reservation_id).then(() => {
      success("¡Reservacion eliminada!");
      if (typeof callback === "function") {
        callback();
      }
      hideModal();
    });
  };

  const getReservationsRatings = (filters) => {
    ReservationsService.getReservationsRatings(filters).then((res) => {
      const { ratings } = res.data;
      dispatch({ type: RESERVATIONS_RATINGS_RECEIVED, payload: ratings });
    });
  };

  return (
    <ReservationsContext.Provider
      value={{
        ...state,
        postAttend,
        postReservacion,
        updateGuestName,
        cancelReservacion,
        getMyReservations,
        eliminarReservacion,
        getReservationsRatings,
        setCurrentFilter
      }}
    >
      {children}
    </ReservationsContext.Provider>
  );
};
