import React from "react";
import { S3_ENDPOINT } from "../../utils";

const UserImage = ({ customer }) => {
  const renderImg = () => {
    if (customer.file !== null) {
      return (
        <img
          src={`${S3_ENDPOINT}/${customer.file.name}.${customer.file.type}`}
          className="profile-thumbnail"
        />
      );
    }
    return (
      <div className="btn btn-round shadow-sm btn-light">
        <i className="fa fa-user"></i>
      </div>
    );
  };
  return <div>{renderImg()}</div>;
};

export default UserImage;
