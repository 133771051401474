import React, { useContext } from "react";
import { getValue } from "../../utils";
import { StreaksContext } from "../../context/StreaksContext";
import { ModalContext } from "../../context/ModalContext";

const StreakForm = ({ handleCancel, handleCallback }) => {
  const { alert } = useContext(ModalContext);
  const { streak, saveStreak, setPropertyStreak } = useContext(StreaksContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (streak.extend_reason === null) {
      return alert("Debes indicar un motivo del cambio.");
    }
    saveStreak(streak, handleCallback);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Fecha de Fin</label>
      <input
        type="date"
        className="form-control mb-3"
        value={getValue(streak, "end_date", "date")}
        onChange={(e) => setPropertyStreak("end_date", e.target.value)}
      />
      <label>Motivo de Cambio</label>
      <input
        type="text"
        className="form-control mb-3"
        value={getValue(streak, "extend_reason")}
        onChange={(e) => setPropertyStreak("extend_reason", e.target.value)}
      />
      <div className="row">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn w-100 text-muted"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn w-100 btn-primary">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default StreakForm;
