import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";

export default function () {
  const {
    title,
    content,
    component,
    children,
    onCancel,
    onSuccess,
    clearModal,
  } = useContext(ModalContext);
  const handleCancel = () => {
    if (typeof onCancel === "function") {
      onCancel();
    }
    clearModal();
  };
  return (
    <div
      className="modal fade"
      id="modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {title && (
            <div className="modal-header border-0">
              <div className="container-fluid px-0">
                <div className="row">
                  <div className="col-6">
                    <h5 className="modal-title">{title}</h5>
                  </div>
                  <div className="col-6 text-end">
                    <button className="btn text-muted" onClick={handleCancel}>
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="modal-body">
            {content}
            {component}
            {children}
          </div>
          {(onSuccess || onCancel) && (
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-secondary"
                data-dismiss="modal"
                onClick={onCancel}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={onSuccess}
              >
                OK
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
