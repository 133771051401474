import React, { useContext, useEffect, useState } from "react";
import PanelTitle from "../../components/global/PanelTitle";
import AddresesTable from "../../components/addresses/AddressesTable";
import { AnaliticasContext } from "../../context/AnaliticasContext";

const AnaliticasAddresses = () => {
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [currentTab, setCurrentTab] = useState('all');
  const [group, setGroup] = useState("city");

  const { addresses, getAddresses } = useContext(AnaliticasContext);

  useEffect(() => {
    let count = 0;

    addresses?.forEach(address => {
      count += address.customers;
    });

    setTotalCustomers(count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses]);

  useEffect(() => {
    getAddresses({
      groupBy: group,
      classType: currentTab
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, currentTab]);

  const renderTabs = () => {
    const tabs = [
      { label: 'Todos', value: 'all'},
      { label: 'Online', value: 'online'},
      { label: 'Presencial', value: 'presencial'},
    ];

    return tabs.map(tab => {
      const active = tab.value === currentTab ? true : false;

      return(
        <li 
          key={tab.value}
          className={`nav-item customer-nav-item`}
          onClick={() => setCurrentTab(tab.value)}
        >
          <p className={`nav-link mb-0 ${active ? 'bg-primary text-white' : 'text-dark'} `}>{tab.label}</p>
        </li>
      )
    })
  }

  return (
    <div className="container-fluid">
      <PanelTitle title="Direcciones" hideButton />
      <div className="card p-3 shadow">
        <div className="row mb-3 align-items-center">
          <div className="col-6">
            <ul className="nav nav-pills d-inline-flex me-3" style={{width: 'max-content'}}>
              {renderTabs()}
            </ul>

            <div className="badge badge-pill bg-primary fs-6">
                Total de Clientes: {totalCustomers}
            </div>
          </div>

          <div className="col-6 text-end">

            <div className="row align-items-center">
              <div className="col-6 text-end">
                <p className="mb-0 small bold">Agrupar por:</p>
              </div>

              <div className="col-6">
                <select
                  value={group}
                  className="form-control"
                  onChange={(e) => setGroup(e.target.value)}
                >
                  <option value="city">Ciudad</option>
                  <option value="state">Estado</option>
                </select>
              </div>
            </div>
            
          </div>
        </div>
        <AddresesTable group={group} addresses={addresses} />
      </div>
    </div>
  );
};
export default AnaliticasAddresses;
