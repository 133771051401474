import React, { useContext, useEffect, useState } from "react";
import CoachForm from "../../components/coaches/CoachForm";
import PanelTitle from "../../components/global/PanelTitle";
import { CoachesContext } from "../../context/CoachesContext";
import { ModalContext } from "../../context/ModalContext";
import CoachesTable from "../../components/coaches/CoachesTable";

const AdminCoaches = () => {
  const [query, setQuery] = useState("");

  const { coaches, setCoach, getCoaches, deleteCoach } =
    useContext(CoachesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getCoaches();
  }, []);

  const handleCancel = () => {
    clearModal();
    setCoach(null);
  };

  const addCoach = () => {
    modalComponent(
      "Agregar Coach",
      <CoachForm idCoach="nueva" handleCancel={handleCancel} />
    );
  };

  const editCoach = (coach) => {
    modalComponent(
      "Editar Coach",
      <CoachForm idCoach={coach.instructor_id} handleCancel={handleCancel} />
    );
  };

  const confirmDelete = (coach) => {
    modalComponent(
      "¡Precaución!",
      <div className="container-fluid px-0">
        <p>
          ¿Estás segura que quieres eliminar a la coach {coach.name}? Esta
          acción NO puede deshacerse.
        </p>
        <div className="row">
          <div className="col-6">
            <button className="btn text-muted w-100" onClick={handleCancel}>
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn btn-danger w-100"
              onClick={() => deleteCoach(coach.instructor_id)}
            >
              Eliminar
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <PanelTitle title="Coaches" onClick={addCoach} />
      <div className="container-fluid px-0 mb-3">
        <input
          type="text"
          value={query}
          className="form-control"
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Buscar paquete por nombre..."
        />
      </div>
      <CoachesTable
        query={query}
        coaches={coaches}
        handleEdit={editCoach}
        handleDelete={confirmDelete}
      />
    </div>
  );
};

export default AdminCoaches;
