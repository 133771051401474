
import api from './api';

const route = '/branches';

const BranchesService = {
  getBranches: () => api.get(route),
  getSingleBranch: (branch_id) => api.get(`${route}/${branch_id}`),
  postBranch: (branch) => api.post(route, { ...branch}),
  putBranch: (branch) => api.put(route, { ...branch}),
  deleteBranch: (Branch) => api.delete(`${route}/${Branch}`),
};

export default BranchesService;