import React, { useState, useEffect, useContext } from "react";
import { ClassInstructorContext } from "../../context/SingleClassContext";
import WeekScheduleAdminDesktop from "./WeekScheduleAdminDesktop";
import ScheduleMonthSelect from "./ScheduleMonthSelect";
import ScheduleWeekSelect from "./ScheduleWeekSelect";
import MonthScheduleAdmin from "./MonthScheduleAdmin";
import WeekScheduleMobile from "./WeekScheduleMobile";
import useBranch from "../../hooks/useBranch";
import utils from "./utils";
import moment from "moment";
import "./schedule.css";

const ScheduleAdmin = () => {
  const [currentClasses, setCurrentClasses] = useState(null);
  const { branch_id } = useBranch();

  const {
    days,
    view,
    setView,
    end_date,
    start_date,
    setEndDate,
    getSchedule,
    setStartDate,
  } = useContext(ClassInstructorContext);

  useEffect(() => {
    const date = utils.getStartDate(view);
    setStartDate(date);
  }, [view]);

  useEffect(() => {
    setEndDate(utils.getEndDate(view, start_date));
  }, [start_date]);

  useEffect(() => {
    if (Array.isArray(days)) {
      if (view === "week") {
        setCurrentClasses(days.slice(0, 7));
      } else {
        setCurrentClasses(days);
      }
    }
  }, [days]);

  useEffect(() => {
    fetchSchedule();
  }, [start_date, end_date, branch_id]);

  const fetchSchedule = () => {
    if (utils.validDates(start_date, end_date)) {
      let startDate = moment(start_date)
        .startOf("isoWeek")
        .format("YYYY-MM-DD");
      let endDate =
        moment(end_date).month() !== moment(start_date).month() &&
        view === "month"
          ? end_date
          : moment(end_date).endOf("isoWeek").format("YYYY-MM-DD");
      getSchedule({
        start_date: startDate,
        end_date: endDate,
      });
    }
  };

  const renderScheduleView = () => {
    if (view === "week") {
      return (
        <div>
          <WeekScheduleAdminDesktop
            weekClasses={currentClasses}
            handleCallback={fetchSchedule}
          />
          <WeekScheduleMobile
            weekClasses={currentClasses}
            handleCallback={fetchSchedule}
          />
        </div>
      );
    }
    return (
      <MonthScheduleAdmin
        monthClasses={currentClasses}
        handleCallback={fetchSchedule}
      />
    );
  };

  const handleNextWeek = () => {
    setStartDate(utils.getStartNext("week", start_date));
  };

  const handlePrevWeek = () => {
    setStartDate(utils.getStartPrev("week", start_date));
  };

  const handleNextMonth = () => {
    setStartDate(utils.getStartNext("month", start_date));
  };

  const handlePrevMonth = () => {
    setStartDate(utils.getStartPrev("month", start_date));
  };

  return (
    <div className="container-fluid px-0" style={{ overflowX: "hidden" }}>
      <div className="container-fluid px-0">
        <div className="row align-items-center mt-3 mb-4">
          <div className="col-12 col-md-4 mb-3">
            <ScheduleMonthSelect
              start_date={start_date}
              handleNext={handleNextMonth}
              handlePrev={handlePrevMonth}
            />
          </div>
          <div className="col-12 col-md-4 mb-3">
            {view === "week" && (
              <ScheduleWeekSelect
                start_date={start_date}
                handleNextWeek={handleNextWeek}
                handlePrevWeek={handlePrevWeek}
              />
            )}
          </div>
          <div className="col-12 col-md-4 mb-3 text-end">
            <div className="btn-group">
              <button
                onClick={() => setView("week")}
                className={`btn border btn-${
                  view === "week" ? "primary" : "light"
                }`}
              >
                Semana
              </button>
              <button
                onClick={() => setView("month")}
                className={`btn border btn-${
                  view === "month" ? "primary" : "light"
                }`}
              >
                Mes
              </button>
            </div>
          </div>
        </div>
      </div>
      {renderScheduleView()}
    </div>
  );
};

export default ScheduleAdmin;
