import moment from "moment";
import React from "react";
import { S3_ENDPOINT } from "../../utils";

const ProfileInfo = ({ customer }) => {
  const renderImg = () => {
    if (customer.file !== null) {
      return (
        <img
          src={`${S3_ENDPOINT}/${customer.file.name}.${customer.file.type}`}
          className="profile-image"
        />
      );
    }
    return (
      <div className="btn btn-round shadow-sm bg-light">
        <i className="fa fa-user"></i>
      </div>
    );
  };

  const renderBirthdate = () => {
    if (customer.birthdate !== null) {
      return (
        <p>
          <i className="fa fa-birthday-cake"></i>{" "}
          {moment(customer.birthdate).utc().format("DD MMM YYYY")}
        </p>
      );
    }
  };

  return (
    <div className="container-fluid px-0">
      <div className="row mb-4">
        <div className="col-12 col-md-4">{renderImg()}</div>
        <div className="col-12 col-md-8">
          <p className="bold">{customer.name}</p>
          {renderBirthdate()}
          <p>
            Member since {moment(customer.createdAt).utc().format("MMM YYYY")}
          </p>
        </div>
      </div>
      <h4>Streaks</h4>
    </div>
  );
};

export default ProfileInfo;
