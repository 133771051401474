import React, { createContext, useReducer } from "react";
import AnaliticasReducer from "../reducers/AnaliticasReducer";
import AnaliticasService from "../services/AnaliticasService";
import {
  INSCRITOS_RECIBIDOS,
  INGRESOS_RECBIDOS,
  PAQUETES_RECIBIDOS,
  RESERVATIONS_RECIBIDAS,
  COACHES_RECIBIDAS,
  FREE_TRIALS_RECIBIDAS,
  VIDEOS_RECIBIDOS,
  LEADERBORARD_RECEIVED,
  DISCOUNTS_RECEIVED,
  TRIAL_CLASS_RECEIVED,
  SIGNUP_REASONS_RECEIVED,
  ADDRESSES_RECEIVED,
} from "../types/analytics";

const initialState = {
  alumnas: null,
  paquetes: null,
  ingresos: null,
};

export const AnaliticasContext = createContext(initialState);

export const AnaliticasProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AnaliticasReducer, initialState);
  
  const getAddresses = (filters) => {
    AnaliticasService.getAddresses(filters).then((res) => {
      dispatch({ type: ADDRESSES_RECEIVED, payload: res.data });
    });
  }

  const getInscritos = (fecha_inicio, fecha_fin) => {
    AnaliticasService.getInscritos(fecha_inicio, fecha_fin).then((res) => {
      dispatch({ type: INSCRITOS_RECIBIDOS, payload: res.data });
    });
  };

  const getPaquetes = (fecha_inicio, fecha_fin) => {
    AnaliticasService.getPaquetes(fecha_inicio, fecha_fin).then((res) => {
      dispatch({ type: PAQUETES_RECIBIDOS, payload: res.data.purchases });
    });
  };

  const getIngresos = (fecha_inicio, fecha_fin) => {
    AnaliticasService.getIngresos(fecha_inicio, fecha_fin).then((res) => {
      dispatch({ type: INGRESOS_RECBIDOS, payload: res.data });
    });
  };

  const getReservaciones = (fecha_inicio, fecha_fin) => {
    AnaliticasService.getReservaciones(fecha_inicio, fecha_fin).then((res) => {
      dispatch({
        type: RESERVATIONS_RECIBIDAS,
        payload: {
          reservaciones: res.data.reservations,
          horas: res.data.capacity,
          days: res.data.days,
        },
      });
    });
  };

  const getInstructores = (fecha_inicio, fecha_fin) => {
    AnaliticasService.getInstructores(fecha_inicio, fecha_fin).then((res) => {
      const { result } = res.data;
      dispatch({ type: COACHES_RECIBIDAS, payload: result });
    });
  };

  const getFreeTrials = (fecha_inicio, fecha_fin) => {
    AnaliticasService.getFreeTrials(fecha_inicio, fecha_fin).then((res) => {
      const { purchases } = res.data;
      dispatch({ type: FREE_TRIALS_RECIBIDAS, payload: purchases });
    });
  };

  const getVideos = (fechaInicio, fechaFin) => {
    AnaliticasService.getVideos(fechaInicio, fechaFin).then((res) => {
      dispatch({ type: VIDEOS_RECIBIDOS, payload: res.data });
    });
  };

  const getLeaderboard = (filters) => {
    AnaliticasService.getLeaderboard(filters).then((res) => {
      const { leaderboard } = res.data;
      dispatch({ type: LEADERBORARD_RECEIVED, payload: leaderboard });
    });
  };

  const getDescuentos = (filters) => {
    AnaliticasService.getDescuentos(filters).then((res) => {
      const { discounts } = res.data;
      dispatch({ type: DISCOUNTS_RECEIVED, payload: discounts });
    });
  };

  const getDescuentosCoach = (filters) => {
    AnaliticasService.getDescuentosCoach(filters).then((res) => {
      const { discounts } = res.data;
      dispatch({ type: DISCOUNTS_RECEIVED, payload: discounts });
    });
  };

  const getOnlineLeaderboard = (filters) => {
    AnaliticasService.getOnlineLeaderboard(filters).then((res) => {
      const { leaderboard } = res.data;
      dispatch({ type: LEADERBORARD_RECEIVED, payload: leaderboard });
    });
  };

  const getTrialClassAnalytics = (filters) => {
    AnaliticasService.getTrialClassAnalytics(filters).then(res => {
      const { trials } = res.data;
      dispatch({ type: TRIAL_CLASS_RECEIVED, payload: trials })
    })
  }
  const getSignupPurchases = filters => {
    AnaliticasService.getSignupPurchases(filters).then(res => {
      const { signup_reasons } = res.data;
      dispatch({ type: SIGNUP_REASONS_RECEIVED, payload: signup_reasons})
    })
  }

  const clearInstructores = () => {
    dispatch({ type: COACHES_RECIBIDAS, payload: null });
  };

  return (
    <AnaliticasContext.Provider
      value={{
        ...state,
        getVideos,
        getPaquetes,
        getIngresos,
        getAddresses,
        getInscritos,
        getDescuentos,
        getFreeTrials,
        getLeaderboard,
        getInstructores,
        getReservaciones,
        clearInstructores,
        getSignupPurchases,
        getDescuentosCoach,
        getOnlineLeaderboard,
        getTrialClassAnalytics,
      }}
    >
      {children}
    </AnaliticasContext.Provider>
  );
};
