import moment from "moment";
import React from "react";
import { formatMonto } from "../../utils";

const VideoRow = ({ key_id, video, hideCoach, hideDate, handleComments }) => {
  const renderRating = () => {
    if (video.class_stars_average !== null) {
      return (
        <span className="d-flex" onClick={() => handleComments(video[key_id], key_id)}>
          <span className="me-2">
            <div>
              {formatMonto(video.class_stars_average)}{" "}
              <i className="fa fa-star ms-1 text-warning"></i>
            </div>
            <p style={{ fontSize: '12px'}} className="mb-0">Clase</p>
          </span>
          <span className="me-2">
            <div>
              {formatMonto(video.coach_stars_average)}{" "}
              <i className="fa fa-star ms-1 text-warning"></i>
            </div>
            <p style={{ fontSize: '12px'}} className="mb-0">Coach</p>
          </span>
          <span>
            {formatMonto(video.comments)}{" "}
            <i className="fa fa-comments ms-1"></i>
          </span>
        </span>
      );
    }
    return <span className="small text-muted">Sin calificar</span>;
  };
  return (
    <tr key={video.video_id}>
      <td>{video.name}</td>
      {!hideCoach && <td>{video.instructor_name}</td>}
      {!hideDate && (
        <td>{moment(video.createdAt).utc().format("DD MMM YYYY")}</td>
      )}
      <td>{video.playbacks}</td>
      <td>{video.unique_playbacks}</td>
      <td>{renderRating()}</td>
    </tr>
  );
};

export default VideoRow;
