import api from "./api";

const route = "/files";

const AdjuntosService = {
  getFormData: (file, data) => {
    const formData = new FormData();
    formData.append("file", file);
    if(data) formData.append("data", data); 
    return formData;
  },
  getAdjunto: () => api.get(route),
  postAdjunto: (formData) =>
    api.post(route, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
};

export default AdjuntosService;
