import React from "react";
import VideoImage from "./VideoImage";
import VideoInfo from "./VideoInfo";
import { Link } from "@reach/router";

const VideoTeaser = ({ video, hideInfo }) => {
  return (
    <div>
      <VideoImage video={video} />
      <div className="row align-items-center border-bottom my-3 pb-2">
        <div className="col-8">
          <h3 className="my-0">{video.name}</h3>
        </div>
        <div className="col-4">
          <Link
            to={`/mytbm/video/${video.video_id}`}
            className="btn w-100 btn-primary"
          >
            <i className="fa fa-play me-2"></i> Ver Video
          </Link>
        </div>
      </div>
      <p>{video.description}</p>
      {!hideInfo && <VideoInfo video={video} />}
    </div>
  );
};

export default VideoTeaser;
