import moment from "moment";
import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "@reach/router";

const OnlineLeaderboardRow = ({ customer, start_date, end_date }) => {
  const { user } = useContext(AuthContext);

  const getDailyAveragePlaybacks = (playbacks) => {
    if (playbacks > 0) {
      const diff = moment(end_date).diff(moment(start_date), "days");
      if (diff > 0) {
        return parseFloat(parseInt(playbacks) / parseInt(diff)).toFixed(2);
      }
    }
  };

  const renderCustomerName = () => {
    if (["super_admin", "admin", "manager"].includes(user.role)) {
      return (
        <Link to={`/myadmin/customer/${customer.customer_id}`}>
          <i className="fa fa-user me-1"></i>
          {customer.name} {customer.last_name}
        </Link>
      );
    }
    return customer.name;
  };

  const renderStreaks = () => {
    if (customer.streaks && customer.streaks !== null) {
      return new Array(customer.streaks).fill(1).map((one) => "🔥");
    }
  };

  return (
    <tr
      key={customer.customer_id}
      className={
        customer.customer_id === user.customer_id ? "bg-secondary" : ""
      }
    >
      <td>{renderCustomerName()}</td>
      <td>
        <i className="fab fa-instagram me-1" /> {customer.instagram}
      </td>
      <td>
        {customer.city !== null && (
          <span>
            {customer.city}, {customer.country}
          </span>
        )}
      </td>
      <td>{customer.playbacks}</td>
      <td>{getDailyAveragePlaybacks(customer.playbacks)} clases</td>
      <td>{renderStreaks()}</td>
    </tr>
  );
};

export default OnlineLeaderboardRow;
