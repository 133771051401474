import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "@reach/router";

const LeaderboardRow = ({ customer }) => {
  const { user } = useContext(AuthContext);

  const renderCustomerName = () => {
    if (["super_admin", "admin", "manager"].includes(user.role)) {
      return (
        <Link to={`/myadmin/customer/${customer.customer_id}`}>
          <i className="fa fa-user me-1"></i>
          {customer.name} {customer.last_name}
        </Link>
      );
    }
    return customer.name;
  };

  return (
    <tr
      key={customer.customer_id}
      className={
        customer.customer_id === user.customer_id ? "bg-secondary" : ""
      }
    >
      <td>{renderCustomerName()}</td>
      <td>
        <i className="fab fa-instagram me-1" /> {customer.instagram}
      </td>
      <td>{customer.reservations}</td>
      <td>{customer.attended}</td>
      <td>{new Array(customer.streaks).fill(1).map((one) => "🔥")}</td>
    </tr>
  );
};

export default LeaderboardRow;
