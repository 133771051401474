import { CARTS_RECEIVED } from "../types/carts";

const cartsReducer = (state, { type, payload }) => {
  switch (type) {
    case CARTS_RECEIVED:
      return { ...state, cart_items: payload };
    default:
      return { ...state };
  }
};

export default cartsReducer;
