import React from "react";
import DiscountRow from "./DescuentoRow";

const DiscountsTable = ({ discounts, hideSales, showCommissions }) => {
  const renderDiscounts = () => {
    if (Array.isArray(discounts)) {
      if (discounts.length === 0) {
        return <p>No hay descuentos registrados.</p>;
      }
      return discounts.map((discount) => (
        <DiscountRow
          showCommissions={showCommissions}
          key={discount.discount_id}
          hideSales={hideSales}
          discount={discount}
        />
      ));
    }
  };

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr className="bg-light bold">
            <th>Codigo</th>
            <th>Creado</th>
            <th>Compras</th>
            {!hideSales && <th>Ventas</th>}
            {showCommissions && <th>Comisiones</th>}
          </tr>
        </thead>
        <tbody>{renderDiscounts()}</tbody>
      </table>
    </div>
  );
};

export default DiscountsTable;
